import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Lato", sans-serif;
  height: 100vh;
  background: #fff;
  .row {
    --bs-gutter-x: 0px;
  }
  .end-login-section {
    text-align: center;
    padding: 20px;
  }

  .heading-text-head {
    font-weight: bolder;
    font-family: Montserrat !important;
    color: #4e5463 !important;
    font-size: 20px;
  }
  .heading-text {
    p {
      font-size: 16px !important;
      font-weight: 400;
      color: #777 !important;
      margin-bottom: 10px;
    }
  }
  a {
    font-family: "Lato", sans-serif !important;
    color: #007bff !important;
    text-decoration: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 1.7 !important;
    background-color: transparent;
  }
  #kt_sign_in_submit {
    height: 42px !important;
    background-color: #075ad7 !important;
    color: #fff !important;
    border-color: #007bff !important;
    font-weight: 500 !important;
    border: 1px solid transparent !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 16px !important;

    border-radius: 0.25rem !important;
  }
  .end-login-section {
    p {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #777;
    }
  }

  @media (min-width: 992px) {
    .login-frame {
      margin-top: -7px;
      padding: 100px 70px 100px 70px;
    }
  }
  .img-wrapper {
    width: 100%;
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100vh;
    }
  }
  .new-ui-reset-password {
    width: 400px;
    background: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    inset: 0;
    position: absolute;
    height: fit-content;
    margin: auto;
    .ant-form-item-explain-error {
      text-align: left;
    }
    #kt_password_reset_submit,
    a {
      border-radius: 8px !important;
      width: 100%;
    }
  }
`;
