import styled from "styled-components";

export const Wrapper = styled.div`
  .card-view-progress {
    background: #eef7ee;
    border: 1px solid #1ab26b;
    border-radius: 8px;
    padding: 10px 15px 15px 15px;
  }
  #tableView {
    scrollbar-color: transparent transparent !important;
    // overflow: hidden !important;
  }
  #tableView1 {
    scrollbar-color: transparent transparent !important;
    // overflow: hidden !important;
  }

  .downArrow {
    cursor: pointer;
    color: #232323 !important;
    &:hover {
      color: #99a1b7 !important;
    }
  }
  .upArrow {
    cursor: pointer;
    color: #232323 !important;
    &:hover {
      color: #99a1b7 !important;
    }
  }
`;
