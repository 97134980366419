import { Button, message, Modal, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";

import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import AddData from "./seller-add";
import {
  RenderTable,
  EditIcon,
  DeleteIcon,
  configModal,
  PropsFilter,
} from "../../../../config";

export default function SellerParameters({ seller_id }) {
  const [modal, contextHolder] = Modal.useModal();
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };

  // const PropsFilter = (type) => {
  //   return {
  //     defaultSortOrder:
  //       type === sortFilters?.field_name
  //         ? DefaultSortType?.[sortFilters?.sort_by]
  //         : [],
  //     sorter: () => {},
  //   };
  // };
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  const getList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `seller-parameter-mapping/seller-log/${seller_id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(
        response?.data?.map((d) => {
          return {
            ...d,
            ...d?.seller_parameter,
            id__: d?.id,
            id_seller_parameter: d?.seller_parameter?.id,
            created_at_seller_parameter: d?.seller_parameter?.created_at,
            updated_at_seller_parameter: d?.seller_parameter?.updated_at,
          };
        })
      );
      setLoading(false);
    } else {
      setList([]);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList({
      ...sortFilters,
    });
    return () => {};
  }, []);

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `seller-parameter-mapping/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      modal.success(configModal("Seller Parameter", response?.message));
      setPage(1);
      setLoading(true);
      getList({ page: 1 });
    } else {
      message.destroy();
      modal.warning(configModal("Seller Parameter", response?.message));
    }
  };

  const columns = [
    {
      title: "ID",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Parameter Group Name",
      dataIndex: "param_group_name",
      key: "param_group_name",
      filterIndex: "param_group_name",
    },
    {
      title: "Parameter",
      dataIndex: "param_name",
      key: "param_name",
      filterIndex: "param_name",
    },
    {
      title: "Type",
      dataIndex: "param_type",
      key: "param_type",
      filterIndex: "param_type",
    },
    {
      title: "Default Value",
      dataIndex: "param_value",
      key: "param_value",
      align: "right",
      filterIndex: "param_value",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Default Lower Value",
      dataIndex: "param_lower_value",
      align: "right",
      key: "param_lower_value",
      filterIndex: "param_lower_value",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Default Upper Value",
      dataIndex: "param_upper_value",
      key: "param_upper_value",
      align: "right",
      filterIndex: "param_upper_value",
      render: (e) => {
        return e || 0;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterIndex: "status",
      render: (e) => {
        return e ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="d-flex">
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(_);
            }}
            className=" me-2"
          >
            {EditIcon}
          </a>

          <Popconfirm
            title="Delete this Seller Parameter"
            description={`Are you sure to delete this ${_?.param_group_name}?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              deleteAction(_?.id__);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="card mt-5">
        <div className="card-header">
          <div className="card-title">Seller Parameters</div>
          <div className="card-toolbar">
            <a
              className="add-btn "
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
            >
              Add New
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          <Table
            // onChange={handleChange}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            pagination={{
              size: "default",
              showSizeChanger: true,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            dataSource={list}
            scroll={{ x: "max-content" }}
            columns={columns?.map((d) => ({
              ...d,
              className: "minWidth-table",
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
          />
        </div>

        {addModal && (
          <AddData
            visible={addModal}
            selectedRow={selectedRow}
            modalType={modalType}
            seller_id={seller_id}
            onClose={() => {
              setSelectedRow({});
              setAddModal(false);
            }}
            callAPI={() => {
              setLoading(true);
              setPage(1);
              getList({ page: 1 });
            }}
          />
        )}
      </div>
    </Wrapper>
  );
}
