import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-collapse {
    border: none;
    border-bottom: none !important;
    background: #ff000000;
    // padding: 10px 2px;
    .ant-collapse-item {
      border-bottom: none;
      button {
        span {
          color: #fff;
        }
      }
    }
    .ant-collapse-header {
      padding: 15px 10px;
    }
    .ant-collapse-content {
      border-top: 1px solid #d9d9d966;
    }
  }
`;
