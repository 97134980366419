import { Button, Form, Input, message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const AddCatalog = ({
  setVisible,
  pageType,
  id,
  getList,
  menuList,
  menuLoading,
}) => {
  const [form] = Form.useForm();
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleTitleChange = (event) => {
    const titleValue = event.target.value;

    const newSlug = titleValue.trim().toLowerCase().replace(/\s+/g, "_");
    form.setFieldsValue({ slug: newSlug });
  };

  const getRecord = async () => {
    const response = await MakeApiCall(`location/${id}`, "get", null, true);

    if (response?.status) {
      setSelectedRow({
        ...response?.data,
        menu_item_id: response?.data?.menu_item_id?.toString(),
      });
      form.setFieldsValue({
        ...response?.data,
        menu_item_id: response?.data?.menu_item_id?.toString(),
      });

      setPageLoading(false);
    } else {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    // setLoading(true);
    return () => {};
  }, []);

  const addLocationCatalog = async (data) => {
    const response = await MakeApiCall(`location`, "post", data, true);

    if (response?.status) {
      setSubmitLoading(false);
      message.success(response?.message);
      getList();
      setVisible();
    } else {
      setSubmitLoading(false);
      message.warning(response?.message);
    }
  };

  const UpdateLocationCatalog = async (data) => {
    const response = await MakeApiCall(`location/${id}`, "put", data, true);

    if (response?.status) {
      setSubmitLoading(false);
      message.success(response?.message);
      getList();
      setVisible();
    } else {
      setSubmitLoading(false);
      message.warning(response?.message);
    }
  };

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (pageType === "edit") {
          setSubmitLoading(true);
          UpdateLocationCatalog({
            ...values,
          });
        } else {
          setSubmitLoading(true);
          addLocationCatalog(values);
        }
      })
      .catch((errorInfo) => {});
  };

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            / Location Catalog
          </div>
        </div>
        <div className="px-10 pt-10">
          <Form form={form} layout="vertical">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input onChange={handleTitleChange} />
            </Form.Item>

            <Form.Item
              name="menu_item_id"
              label="Menu Item"
              rules={[
                { required: true, message: "Please input the Menu Item!" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={menuList}
                loading={menuLoading}
                placeholder="Select Menu"
              />
            </Form.Item>
            {pageType === "edit" ? (
              <></>
            ) : (
              <Form.Item
                name="slug"
                label="Slug"
                rules={[{ required: true, message: "Please input the Slug!" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Slug"}
                  disabled
                ></Input>
              </Form.Item>
            )}
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={() => setVisible()}>
            Cancel
          </Button>
          <Button
            onClick={handleExternalSubmit}
            key="submit"
            className="ms-3"
            loading={submitLoading}
            type="primary"
          >
            {pageType === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCatalog;
