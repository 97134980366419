import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import moment from "moment";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { RenderTable } from "../../../../config";

const EventTypes = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const columns = [
    {
      title: "ID",

      align: "center",
      render: (text, record, index) => (page - 1) * pageSize + 1 + index,
    },
    {
      title: "Event",
      dataIndex: "event_name",
      key: "event_name",
      sorter: (a, b) => a.event_name.localeCompare(b.event_name),
    },
    {
      title: "Created At (ET)",
      align: "left",
      sorter: (a, b) => {
        // Assuming time_spent is a duration in a comparable format (e.g., minutes or seconds)
        return a.created_at - b.created_at;
      },
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },

    {
      title: "Updated At (ET)",
      align: "left",
      sorter: (a, b) => {
        // Assuming time_spent is a duration in a comparable format (e.g., minutes or seconds)
        return a.updated_at - b.updated_at;
      },
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.updated_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
  ];
  const GetList = async (data) => {
    const response = await MakeApiCall(
      `seller-tracking/event-types?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  useEffect(() => {
    setLoading(true);
    GetList();
    return () => {};
  }, []);
  return (
    <div className="card">
      <div className="card-body scroll-show pt-0 ">
        <Table
          dataSource={list}
          fixed={true}
          sticky={{
            offsetHeader: "0px",
          }}
          // columns={columns}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "even-row" : "odd-row";
          }}
          columns={columns?.map((d) => ({
            ...d,

            render: (props, row, index) => RenderTable(props, row, index, d),
          }))}
          bordered
          className="mt-5"
          pagination={false}
          scroll={{ x: "max-content", y: "calc(100vh - 410px)" }}
          size="small"
        />
      </div>
      <div className="card-footer pt-0 pb-5">
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            GetList({
              page: 1,
              pageSize: e,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            GetList({
              page: e,
            });
          }}
        />
      </div>
    </div>
  );
};

export default EventTypes;
