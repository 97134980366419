import { Button, Form, Input, Table, message } from "antd";
import React, { useState } from "react";

import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect } from "react";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import { TableLoading } from "../../../components/table-animation";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../config";
import { Wrapper } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import SellerParameters from "./seller-parameters";

const SystemParameters = () => {
  const history = useHistory();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [totalPageSeller, setTotalPageSeller] = useState(0);
  const [pageSizeSeller, setPageSizeSeller] = useState(20);
  const [pageSeller, setPageSeller] = useState(1);

  const [sellerLoading, setSellerLoading] = useState(true);
  const [sellerList, setSellerList] = useState([]);

  const [modalLoading, setModalLoading] = useState(false);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [sortFilters_, setSortFilters_] = useState({
    field_name: null,
    sort_by: null,
  });

  const getRecord = async (id) => {
    const response = await MakeApiCall(
      `admin/system-parameter-log/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});
      setIsVisible(true);
    } else {
      message.error(response?.message);
    }
  };

  const handleEdit = (data) => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${data?.id}`
    );
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");
  useEffect(() => {
    if (selectedID) {
      getRecord(selectedID);
    }
  }, [selectedID]);

  const updateRow = async (row, data) => {
    const response = await MakeApiCall(
      `admin/system-parameter-log/${row?.id}`,
      "put",
      {
        system_parameter_id: row?.system_parameter_id,
        param_value_new: parseInt(data?.param_value_new || 0),
      },
      true
    );

    if (response?.status) {
      setPage(1);
      setSelectedRow({});
      setLoading(true);
      getList({
        page: 1,
      });
      setModalLoading(false);
      setIsVisible(false);
      history.push(`/settings/parameters`);
    } else {
      setModalLoading(false);
      message.error(response?.message);
    }
  };
  const resetRow = async (row, data) => {
    const response = await MakeApiCall(
      `admin/system-parameter-log/reset/${row?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setPage(1);
      setSelectedRow({});
      setLoading(true);
      getList({
        page: 1,
      });
      setModalLoading(false);
      setIsVisible(false);
    } else {
      setModalLoading(false);
      message.error(response?.message);
    }
  };
  const addRow = async (data) => {
    const response = await MakeApiCall(`system-parameter`, "post", data, true);

    if (response?.status) {
      setList([...list, response?.data]);
      setModalLoading(false);
      setIsVisible(false);
      history.push(`/settings/parameters`);
    } else {
      setModalLoading(false);
      message.error(response?.message);
    }
  };
  const getList = async (data) => {
    const response = await MakeApiCall(
      `system-parameter-log?field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data || []);
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const getUpdateList = async (data) => {
    setSellerLoading(true);
    const response = await MakeApiCall(
      `system-parameter-log/seller-log?field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSellerList(response?.data || []);
      // setTotalPageSeller(response?.data?.pagination?.totalCount || 0);
      setSellerLoading(false);
    } else {
      setTotalPageSeller(0);
      setSellerLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    getUpdateList();
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  // const PropsFilter = (type) => {
  //   return {
  //     defaultSortOrder:
  //       type === sortFilters?.field_name
  //         ? DefaultSortType?.[sortFilters?.sort_by]
  //         : [],
  //     sorter: () => {},
  //   };
  // };
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    setLoading(true);
    getList({ ...obj });
  };

  const sortType_ = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType_ = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter_ = (type) => {
    return {
      defaultSortOrder:
        type === sortFilters_?.field_name
          ? DefaultSortType_?.[sortFilters_?.sort_by]
          : [],
      sorter: () => {},
    };
  };
  const handleChange_ = (_, __, sorter) => {
    const obj = {
      field_name: sortType_?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType_?.[sorter?.order] || "",
    };
    setSortFilters_(obj);
    setSellerLoading(true);
    getUpdateList({ ...obj });
  };

  const handleOk = (values) => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      updateRow(selectedRow, values);
    } else {
      addRow(values);
      setModalLoading(true);
    }
  };

  const columns = [
    {
      title: "#",
      width: 100,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Parameter",
      dataIndex: "param_name",
      key: "param_name",
      width: 300,
      filterIndex: "param_name",
    },
    {
      title: "Value (Percentage)",
      dataIndex: "param_value_new",
      key: "param_value_new",
      filterIndex: "param_value_new",
      align: "right",
      width: 180,
      render: (value) => `${value}%`,
    },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      width: 190,
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      title: "Action",
      width: 300,
      render: (e) => {
        return (
          <div className="d-flex">
            <a href="#" className=" me-2" onClick={() => handleEdit(e)}>
              {EditIcon}
            </a>
            <a
              style={{
                color: "#065ad8",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                resetRow(e, {
                  system_parameter_id: e?.system_parameter_id,
                  param_value_new: 3,
                });
              }}
            >
              <svg
                width={24}
                className="ms-2"
                height={24}
                fill="#065ad8"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.942 3.791c.3.3.3.785 0 1.084L6.184 6.633H14.3a6.9 6.9 0 1 1 0 13.8H8.167a.767.767 0 0 1 0-1.533H14.3a5.367 5.367 0 1 0 0-10.733H6.184l1.758 1.758a.767.767 0 0 1-1.084 1.084L3.79 7.942c-.3-.3-.3-.785 0-1.084L6.858 3.79c.3-.3.785-.3 1.084 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <span style={{ position: "relative", top: 2 }}>
                Reset to Default
              </span>
            </a>
          </div>
        );
      },
    },
  ];
  const columnsLog_ = [
    {
      title: "#",

      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Parameter",
      filterIndex: "param_name",
      dataIndex: "param_name",
      key: "param_name",
    },
    {
      title: "Old value (%)",
      dataIndex: "param_value_old",
      key: "param_value_old",

      filterIndex: "param_value_old",
      render: (value) => `${value}`,
    },
    {
      title: "Changed value (%)",
      dataIndex: "param_value_new",
      key: "param_value_new",

      filterIndex: "param_value_new",
      render: (value) => `${value}`,
    },

    {
      title: "Date and time (ET)",
      filterIndex: "updated_at",
      dataIndex: "updated_at",
      key: "updated_at",

      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
  ];
  const columnsLog = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (key) => {
        return <span>{key}</span>;
      },
    },
    {
      title: "Parameter",
      filterIndex: "param_name",
      dataIndex: "param_name",
      key: "param_name",
      // width: 300,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",

      filterIndex: "email",
      render: (value) => `${value}`,
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      filterIndex: "seller_name",
      render: (value) => `${value}`,
    },
    {
      title: "Old value (%)",
      dataIndex: "param_value_old",
      key: "param_value_old",
      align: "right",
      filterIndex: "param_value_old",
      render: (value) => `${value}`,
    },
    {
      title: "Changed value (%)",
      dataIndex: "param_value_new",
      key: "param_value_new",
      align: "right",
      filterIndex: "param_value_new",
      render: (value) => `${value}`,
    },

    // {
    //   title: "Changed value (%)",
    //   dataIndex: "param_value_new",
    //   key: "param_value_new",
    //   // width: 180,
    //   filterIndex: "param_value_new",
    //   render: (value) => `${value}`,
    // },

    {
      title: "Date and time (ET)",
      filterIndex: "updated_at",
      align: "center",
      dataIndex: "updated_at",
      key: "updated_at",
      // width: 180,
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
  ];

  if (isVisible) {
    return (
      <div
        className="custom-ui px-5 mt-5"
        style={{
          // display: "flex",
          // justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="card">
          <div className="card-body">
            <Form
              name="parameterValueForm"
              style={{ marginTop: "20px" }}
              initialValues={{ ...selectedRow }}
              onFinish={handleOk}
            >
              <Form.Item
                name="param_name"
                rules={[
                  { required: true, message: "Please input your parameter!" },
                ]}
              >
                <Input disabled placeholder="Parameter" />
              </Form.Item>
              <Form.Item
                name="param_value_new"
                rules={[
                  { required: true, message: "Please input your value!" },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Input must be a number!",
                  },
                ]}
              >
                <Input placeholder="Value" />
              </Form.Item>
              <div className="row gap-2 mb-4">
                <div className="col-12 " style={{ color: "#6c757d" }}>
                  Deployed At (ET):{" "}
                  {moment(new Date(parseInt(selectedRow.created_at) * 1000))
                    .tz("America/New_York")
                    .format("MM/DD/YYYY hh:mm A")}
                </div>
                <div className="col-12" style={{ color: "#6c757d" }}>
                  Published At (ET):{" "}
                  {moment(new Date(parseInt(selectedRow.created_at) * 1000))
                    .tz("America/New_York")
                    .format("MM/DD/YYYY hh:mm A")}
                </div>
                <div className="col-12" style={{ color: "#6c757d" }}>
                  Republished At (ET):{" "}
                  {moment(new Date(parseInt(selectedRow.updated_at) * 1000))
                    .tz("America/New_York")
                    .format("MM/DD/YYYY hh:mm A")}
                </div>
              </div>
              <Form.Item>
                <Button loading={modalLoading} type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  type="dashed"
                  className="ms-3"
                  onClick={() => {
                    history.push(`/settings/parameters`);
                    setIsVisible(false);
                  }}
                >
                  Close
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <Wrapper
        className="custom-ui px-5 mt-5"
        style={{
          // display: "flex",
          // justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="card">
          <div className="card-header">
            <div className="card-title">System Parameters</div>
            <div className="card-toolbar">
              {/* <Button type="primary" onClick={showModal} className="">
                Add
              </Button> */}
            </div>
          </div>
          <div className="card-body pt-0 ">
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className=" ">
                <Table
                  dataSource={list}
                  scroll={{ x: "max-content" }}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  // onChange={handleChange}
                  columns={columns?.map((d) => ({
                    ...d,
                    className: "minWidth-table",
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  }))}
                  loading={loading}
                  pagination={true}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        dataSource={record.child}
                        scroll={{ x: "max-content" }}
                        columns={columnsLog_?.map((d) => ({
                          ...d,
                        }))}
                        loading={loading}
                        pagination={true}
                        rowClassName={(record, index) => {
                          return index % 2 === 0 ? "even-row" : "odd-row";
                        }}
                        bordered
                        size="small"
                      />
                    ),
                    expandIcon: (props) => {
                      if (props?.record.child?.length === 0) return;
                      if (props.expanded) {
                        return (
                          <Button
                            type="link"
                            icon={<CaretDownOutlined />}
                            onClick={(e) => props.onExpand(props.record, e)}
                          />
                        );
                      } else {
                        return (
                          <Button
                            type="link"
                            icon={<CaretRightOutlined />}
                            onClick={(e) => props.onExpand(props.record, e)}
                          />
                        );
                      }
                    },
                    rowExpandable: (record) => record.child?.length !== 0,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-body scroll-show">
            {sellerLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className="">
                <Table
                  dataSource={sellerList?.map((d, i) => ({
                    ...d,
                    key: (page - 1) * pageSize + 1 + i,
                  }))}
                  // scroll={{ x: "max-content" }}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  rowKey={"id"}
                  // onChange={handleChange_}
                  columns={columnsLog?.map((d) => ({
                    ...d,
                    className: "minWidth-table",
                    width: "200px",
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    // render: (props, row, index) =>
                    // RenderTable(props, row, index, d),
                  }))}
                  loading={sellerLoading}
                  pagination={true}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        dataSource={record.child?.map((r, o) => ({
                          ...r,
                          key: `${record?.key}.${o + 1}`,
                        }))}
                        // showHeader={false}
                        scroll={{ x: "max-content" }}
                        // onChange={handleChange_}
                        columns={columnsLog?.map((d) => ({
                          ...d,
                          className: "minWidth-table",
                          ...(d?.filterIndex
                            ? PropsFilter(d?.filterIndex)
                            : ""),
                          // width: "20%",
                        }))}
                        loading={sellerLoading}
                        pagination={false}
                        rowClassName={(record, index) => {
                          return index % 2 === 0 ? "even-row" : "odd-row";
                        }}
                        bordered
                        size="small"
                      />
                    ),
                    expandIcon: (props) => {
                      if (props?.record.child?.length === 0) return;
                      if (props.expanded) {
                        return (
                          <Button
                            type="link"
                            icon={<CaretDownOutlined />}
                            onClick={(e) => props.onExpand(props.record, e)}
                          />
                        );
                      } else {
                        return (
                          <Button
                            type="link"
                            icon={<CaretRightOutlined />}
                            onClick={(e) => props.onExpand(props.record, e)}
                          />
                        );
                      }
                    },
                    rowExpandable: (record) => record.child?.length !== 0,
                  }}
                />
              </div>
            )}

            {/* <Pagination
              loading={sellerLoading}
              pageSize={pageSizeSeller}
              page={pageSeller}
              totalPage={totalPageSeller}
              onPerPage={(e) => {
                setSellerLoading(true);
                setPageSizeSeller(e);
                setPageSeller(1);
                getUpdateList({
                  page: 1,
                  pageSize: e,
                  ...sortFilters_,
                });
              }}
              onPageNo={(e) => {
                setSellerLoading(true);
                setPageSeller(e);
                getUpdateList({
                  page: e,
                  ...sortFilters_,
                });
              }}
            /> */}
          </div>
        </div>
        <SellerParameters />
      </Wrapper>
    </>
  );
};

export default SystemParameters;
