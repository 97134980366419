import React, { useContext } from "react";
import { Wrapper } from "./style";
import ReactApexChart from "react-apexcharts";
import { GlobalContext } from "../../../common-context";
import CredentialsError from "../../credential-page";

const Performance360 = () => {
  const generateHeartbeatData = () => {
    const data = [];
    for (let i = 1; i <= 200; i++) {
      data.push(Math.sin(i * 0.1) * 20 + 50); // Adjust amplitude and frequency as needed
    }
    return data;
  };

  const chartOptions = {
    chart: {
      type: "line",
      // height: 100,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      width: 1,
      curve: "straight",
    },
    colors: ["#FF0000"],

    series: [
      {
        name: "Sales",
        data: [
          0, 50, 0, 0, 0, 25, 0, 0, 0, 10, 0, 0, 25, 0, 50, 0, 0, 0, 25, 0, 0,
          0, 10, 0, 0, 25, 0, 50, 0, 0, 0, 25, 0, 0, 0, 10, 0, 0, 25, 0, 50, 0,
          0, 0, 25, 0, 0, 0, 10, 0, 0, 25, 0, 50, 0, 0, 0, 25, 0, 0, 0, 10, 0,
          0, 25,
        ],
      },
    ],
    yaxis: {
      min: 0,
    },
    // title: {
    //   text: "$135,965",
    //   offsetX: 0,
    //   style: {
    //     fontSize: "24px",
    //   },
    // },
    // subtitle: {
    //   text: "Profits",
    //   offsetX: 0,
    //   style: {
    //     fontSize: "14px",
    //   },
    // },
  };

  const contextValue = useContext(GlobalContext);
  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (lws !== 1 || sp !== 1) {
    return <CredentialsError type="performance-360" />;
  }

  return (
    <Wrapper id="kt_content_container" className="container-fluid">
      {["ACOS", "TACOS"]?.map((r, j) => (
        <div className="row row-card mb-5" key={j}>
          <div className="col-12 fw-bold text-gray-900 mb-3">{r}</div>
          <div className="col-12">
            <div className="row">
              {["Week 49", "Week 50", "Week 51", "Week 52"]?.map((d, i) => (
                <div className="col-3" key={i}>
                  <div className="d-flex border border-gray-300 border-dashed rounded justify-content-between flex-column flex-center overflow-hidden h-150px py-4">
                    <div>
                      <ReactApexChart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="line"
                        height={100}
                      />
                    </div>
                    <span className="text-gray-600 fw-bold fs-6 lh-1">{d}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {["ACOS", "TACOS"]?.map((r, j) => (
        <div className="row row-card mb-5" key={j}>
          <div className="col-12 fw-bold text-gray-900 mb-3">{r}</div>
          <div className="col-12">
            <div className="row">
              {["August", "September", "October", "November"]?.map((d, i) => (
                <div className="col-3" key={i}>
                  <div className="d-flex border border-gray-300 border-dashed rounded justify-content-between flex-column flex-center overflow-hidden h-150px py-4">
                    <div>
                      <ReactApexChart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="line"
                        height={100}
                      />
                    </div>
                    <span className="text-gray-600 fw-bold fs-6 lh-1">{d}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Wrapper>
  );
};

export default Performance360;
