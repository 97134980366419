import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  // .ant-tabs-tab {
  //   background-color: #fff !important;
  //   border-radius: 0.475rem !important;
  //   color: var(--bs-nav-link-color);
  //   font-size: 0.95rem !important;
  // }
  // .ant-tabs-tab-active {
  //   background-color: var(--bs-primary) !important;
  //   .ant-tabs-tab-btn {
  //     color: #fff !important;
  //   }
  // }
  .custom-tabs {
  }
  .createDateRangePicker {
    .ant-picker-presets {
      ul {
        display: flex;

        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 110px;
      }

      li {
        height: fit-content;
        margin: 5px 0;
      }
    }
  }
  .custom-tabs-container {
    display: flex;
    align-items: center;
  }

  .ant-tabs-nav-more {
    margin-left: 0px !important;
    padding: 8px 0px !important;
  }
  .custom-tabs-navigation {
    display: flex;
    align-items: center;
  }

  .custom-tabs {
    overflow-x: auto;
    white-space: nowrap;
    flex: 1;
    margin: 0 10px;
  }

  .custom-tabs .ant-tabs-nav {
    margin-bottom: 0;
  }

  .custom-tabs::-webkit-scrollbar {
    display: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .t-data {
    background: #f4f3f4;
    padding: 5px;
    border-top: 10px solid white;
  }
  .textbox-view {
    border-bottom: 1px solid #1f97d352;
    padding-bottom: 10px;
    border-top: 1px solid #1f97d352;
    padding-top: 10px;
  }
  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #f1f1f1 !important;
    color: #202020 !important;
  }

  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }
  .custom-ui .ant-table-thead .ant-table-cell {
    background-color: lightyellow !important;
  }
`;
export const COGSWrapper = styled.div`
  .ant-table-expanded-row-fixed {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
    table {
      thead {
        tr {
          background: #fff;
          th {
            font-weight: 500;
            border: 0.3px solid #e9ecef;
            padding: 1rem;
            font-size: 14px;
            color: #000;
          }
        }
      }

      tbody tr:nth-of-type(odd) {
        background: #f8f9fa !important;
        .t-data {
          background: #f8f9fa !important;
        }
      }
      tbody tr:nth-of-type(even) {
        .t-data {
          background: #fff !important;
        }
        background: #fff !important;
      }
    }
  }
  .textbox-view {
    border-right: 2px solid #eaecef !important;
    input {
      border: 1px solid #eaecef !important;
      background: #f8f9fa !important;
    }
    .ant-picker {
      border-color: #f8f9fa !important;
      background: #f8f9fa !important;
    }
  }
`;
