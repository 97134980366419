import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Input,
  Menu,
  Popover,
  Space,
  Tooltip,
  message,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import Icons from "../../../../components/icons";

export default function CreateMenu({
  close,
  getListAPI,
  // selectedRow,
  // setSelectedRow,
  pageType,
  id,
}) {
  const [selectedRow, setSelectedRow] = useState({});
  const [form] = Form.useForm();
  const [icons, setIcons] = useState([]);
  const [iconQuery, setIconQuery] = useState("");
  const [menu, setMenu] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedChildIcon, setSelectedChildIcon] = useState({});
  const [currentChildIndex, setCurrentChildIndex] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleIconClick = () => {
    setShowPopover(true);

    setCurrentChildIndex(null);
  };

  // useEffect(() => {
  //   setMenu([{ ...selectedRow, children: selectedRow?.children_data }]);
  //   setSelectedIcon(selectedRow?.icon || "");
  //   if (Object.keys(selectedRow || {})?.length !== 0) {
  //     selectedRow?.children_data?.map((child, index) => {
  //       setSelectedChildIcon((prevState) => ({
  //         ...prevState,
  //         [index]: child?.icon,
  //       }));
  //     });
  //   }
  //   return () => {};
  // }, []);

  const handleChildIconClick = (index) => {
    setShowPopover(true);
    setCurrentChildIndex(index);
  };

  const handleIconSelect = (icon) => {
    if (currentChildIndex === null) {
      setSelectedIcon(icon);
      form.setFieldsValue({ icon });
      setShowPopover(false);
      setIconQuery("");
      setIcons([]);
      updateMenu(form.getFieldsValue());
    } else {
      setSelectedChildIcon((prevState) => ({
        ...prevState,
        [currentChildIndex]: icon,
      }));
      // Update the children array in form values
      const updatedChildren = form
        .getFieldValue("children")
        ?.map((child, index) => {
          if (index === currentChildIndex) {
            return { ...child, icon };
          }
          return child;
        });
      form.setFieldsValue({ children: updatedChildren });

      setShowPopover(false);
      setIconQuery("");
      setIcons([]);
      updateMenu(form.getFieldsValue());
    }
  };

  const handleSearch = async (data) => {
    try {
      const response = await axios.get(
        `https://api.iconify.design/search?query=${iconQuery}`
      );
      setIcons(response.data.icons);
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };

  const addMenus = async (values) => {
    setSubmitLoading(true);
    const response = await MakeApiCall(`menu-item`, "post", values, true);

    if (response?.status) {
      getListAPI();
      close();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      message.warning(response?.message);
    }
  };
  const editMenus = async (values) => {
    setSubmitLoading(true);
    const response = await MakeApiCall(
      `menu-item/${selectedRow?.id}`,
      "put",
      values,
      true
    );

    if (response?.status) {
      getListAPI();
      close();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      message.warning(response?.message);
    }
  };

  const deleteRow = async (id) => {
    const response = await MakeApiCall(`menu-item/${id}`, "delete", null, true);

    if (response?.status) {
      message.destroy();

      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const RemoveSub = (index, id) => {
    deleteRow(id);
    setSelectedRow((prev) => {
      return {
        ...prev,
        children_data: prev?.children_data?.filter(
          (d, i) => i !== parseInt(index)
        ),
      };
    });
    setSelectedChildIcon((prev) => {
      const newObject = { ...prev };
      delete newObject[index];
      return newObject;
    });
  };
  const onFinish = (values) => {
    if (Object?.keys(selectedRow || {})?.length === 0) {
      addMenus({
        ...values,
        is_restricted: values?.is_restricted ? 1 : 0,
        children: values?.children?.map((d) => ({
          ...d,
          is_restricted: d?.is_restricted ? 1 : 0,
        })),
      });
    } else {
      editMenus({
        ...values,
        is_restricted: values?.is_restricted ? 1 : 0,
        children: values?.children?.map((d) => ({
          ...d,
          is_restricted: d?.is_restricted ? 1 : 0,
        })),
      });
    }
  };

  const iconContent = (
    <div
      style={{
        padding: "10px",
        width: 400,
        maxHeight: 280,
        overflow: "auto",
      }}
    >
      <Space wrap>
        {icons?.map((icon, index) => (
          <Icon
            key={index}
            icon={icon}
            width="40"
            height="40"
            onClick={() => handleIconSelect(icon)}
            style={{ cursor: "pointer" }}
          />
        ))}
      </Space>
    </div>
  );

  const getRecord = async () => {
    const response = await MakeApiCall(`menu-item/${id}`, "get", null, true);

    if (response?.status) {
      const selected =
        {
          ...response?.data?.[0],
          children_data: response?.data?.[0]?.children,
        } || {};

      form.setFieldsValue({
        ...selected,
        children: selected?.children_data,
      });
      setSelectedRow(selected || {});
      setPageLoading(false);
      setMenu([
        {
          ...selected,
          children: selected?.children_data,
        },
      ]);
      setSelectedIcon(selected?.icon || "");
      if (Object.keys(selected || {})?.length !== 0) {
        selected?.children_data?.map((child, index) => {
          setSelectedChildIcon((prevState) => ({
            ...prevState,
            [index]: child?.icon,
          }));
        });
      }
      // setLoading(false);
    } else {
      setSelectedRow({});
      // setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    // setLoading(true);
    return () => {};
  }, []);

  const updateMenu = (values) => {
    const newMenu = values ? [values] : [];
    setMenu(newMenu);
  };

  return (
    <Wrapper>
      <div className="row">
        <div className="col-md-12 col-lg-9">
          <Card
            title={
              <>
                <span
                  className="me-3 cursor-pointer"
                  onClick={() => {
                    close();
                  }}
                >
                  <Icons type="home" />
                </span>
                / Menu
              </>
            }
            className="menu-card"
          >
            <Form
              form={form}
              name="dynamic_form"
              onFinish={onFinish}
              initialValues={{
                ...selectedRow,
                children: selectedRow?.children_data,
              }}
              autoComplete="off"
              onValuesChange={(_, allValues) => updateMenu(allValues)}
            >
              <Space
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item name="icon" label="Icon">
                  <Popover
                    content={iconContent}
                    title={
                      <div className="d-flex justify-content-between align-items-center">
                        <span style={{ position: "relative", top: "-6px" }}>
                          Search Icons
                        </span>
                        <Space>
                          <Input
                            placeholder="Search for icons"
                            value={iconQuery}
                            onChange={(e) => setIconQuery(e.target.value)}
                            onPressEnter={handleSearch}
                            style={{ marginBottom: "8px" }}
                          />
                          <Button
                            type="primary"
                            onClick={handleSearch}
                            style={{ marginBottom: "8px" }}
                          >
                            Search
                          </Button>
                        </Space>
                      </div>
                    }
                    trigger="click"
                    open={showPopover && currentChildIndex === null}
                    onOpenChange={(visible) => setShowPopover(visible)}
                  >
                    <div
                      className="icon-container"
                      id="icon-container-parent"
                      onClick={() => {
                        handleIconClick();
                      }}
                    >
                      {selectedIcon && (
                        <div
                          className="cross-icon"
                          onClick={() => setSelectedIcon(null)}
                        >
                          <Icon
                            icon="charm:cross"
                            width="20"
                            height="20"
                            style={{ cursor: "pointer", color: "black" }}
                          />{" "}
                        </div>
                      )}

                      <Icon
                        icon={selectedIcon || "mdi:add"} // charm:cross
                        width="40"
                        height="40"
                        style={{ cursor: "pointer", color: "black" }}
                      />
                    </div>
                  </Popover>
                </Form.Item>

                <Form.Item
                  name="url"
                  label="URL"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        if (value && value.includes("/")) {
                          return Promise.reject("URL cannot contain /");
                        }
                        return Promise.resolve();
                      },
                    }),
                    ({ getFieldValue }) => ({
                      required: getFieldValue("children")?.length === 0,
                      message: "URL is required",
                    }),
                    // {
                    //   required: true,
                    //   message: "URL is required",
                    // },
                    // {
                    //   validator: (_, value) => {
                    //     if (value && value.includes("/")) {
                    //       return Promise.reject("Url cannot contain /");
                    //     }
                    //     return Promise.resolve();
                    //   },
                    // },
                  ]}
                >
                  <Input prefix="/" placeholder="URL" />
                </Form.Item>

                <Form.Item
                  name="title"
                  label="Label"
                  rules={[{ required: true, message: "Label is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name="is_restricted"
                  label="Restricted"
                >
                  <Checkbox />
                </Form.Item>
              </Space>

              <div className="child-list">
                <Form.List name="children" {...selectedRow.children_data}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <Space
                            key={key}
                            className="submenu-item"
                            style={{ borderBottom: fields }}
                            // style={{ backgroundColor: getBackgroundColor(index) }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              label="Icon"
                              name={[name, "icon"]}
                              fieldKey={[fieldKey, "icon"]}
                            >
                              <Popover
                                content={iconContent}
                                title={
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span>Search Icons</span>
                                    <Space>
                                      <Input
                                        placeholder="Search for icons"
                                        value={iconQuery}
                                        onChange={(e) =>
                                          setIconQuery(e.target.value)
                                        }
                                        onPressEnter={() => handleSearch()}
                                        style={{ marginBottom: "8px" }}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={() => handleSearch()}
                                        style={{ marginBottom: "8px" }}
                                      >
                                        Search
                                      </Button>
                                    </Space>
                                  </div>
                                }
                                trigger="click"
                                visible={
                                  showPopover && currentChildIndex === index
                                }
                                onVisibleChange={(visible) =>
                                  setShowPopover(visible)
                                }
                              >
                                <div
                                  className="icon-container"
                                  onClick={() => {
                                    handleChildIconClick(index);
                                  }}
                                >
                                  {selectedChildIcon[index] && (
                                    <div
                                      className="cross-icon"
                                      onClick={() => {
                                        setSelectedChildIcon({
                                          ...selectedChildIcon,
                                          [index]: null,
                                        });
                                      }}
                                    >
                                      <Icon
                                        icon="charm:cross"
                                        width="20"
                                        height="20"
                                        style={{
                                          cursor: "pointer",
                                          color: "black",
                                        }}
                                      />{" "}
                                    </div>
                                  )}
                                  <Icon
                                    icon={selectedChildIcon[index] || "mdi:add"}
                                    width="40"
                                    height="40"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Popover>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="URL"
                              name={[name, "url"]}
                              fieldKey={[fieldKey, "url"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Child url is required",
                                },
                                {
                                  validator: (_, value) => {
                                    if (value && value.includes("/")) {
                                      return Promise.reject(
                                        "Url cannot contain /"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input prefix="/" placeholder="Child URL" />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              label="Menu Title"
                              name={[name, "title"]}
                              fieldKey={[fieldKey, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Child menu title is required",
                                },
                              ]}
                            >
                              <Input placeholder="Child Menu Title" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "is_restricted"]}
                              fieldKey={[fieldKey, "is_restricted"]}
                              label="Restricted"
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                            <MinusCircleOutlined
                              className="remove-icon"
                              onClick={() => {
                                if (
                                  form.getFieldValue("children")?.[name]?.id
                                ) {
                                  RemoveSub(
                                    index,
                                    form.getFieldValue("children")?.[name]?.id
                                  );
                                }
                                remove(name);
                              }}
                            />
                          </Space>
                        )
                      )}
                      <Form.Item className="d-flex justify-content-end add-child-btn">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          className="add-submenu-button"
                        >
                          {fields.length === 0 ? "Add Submenu" : fields.length}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>

              <div className="form-footer">
                <Form.Item className="d-flex justify-content-end mt-2">
                  <Button
                    onClick={() => close()}
                    className="me-3"
                    type="dashed"
                  >
                    Close
                  </Button>
                  <Button
                    loading={submitLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Card>
        </div>
        <div
          className="col-md-12 col-lg-3 user-menu-demo  px-6"
          style={{ padding: "10px", borderRadius: "7px", maxHeight: "340px" }}
        >
          <span
            className="fw-bold me-2 lh-1 ls-n2 mt-1"
            style={{
              color: "#FFF",
              fontSize: "22px",
            }}
          >
            Demo Side Bar
          </span>
          <div
            style={{ height: 1, background: "b4b4b4" }}
            className="mt-3 mb-5"
          />

          <div className="d-flex align-items-center justify-content-center">
            {menu?.filter((d) => d?.title)?.length === 0 ? (
              <Empty />
            ) : (
              <Menu
                theme="light"
                mode="inline"
                openKeys={[menu?.[0]?.url]} // replace with the actual key
                selectedKeys={[menu?.[0]?.url]}
                onClick={(e) => {}}
                items={menu?.map(({ url, icon, title, children = [] }) => ({
                  key: url,
                  icon: <Icon icon={icon} />,
                  label: (
                    <>
                      {title}
                      {children && children?.length > 0 && (
                        <Badge
                          className="ms-2"
                          count={children.length}
                          showZero
                          color="#01c0c8"
                        />
                      )}
                    </>
                  ),
                  ...(children.length > 0 && {
                    children: children
                      ?.filter((d) => d)
                      ?.map(({ url, icon, title }) => ({
                        key: url,
                        icon: <Icon icon={icon} />,
                        label: title,
                      })),
                  }),
                }))}
              />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
