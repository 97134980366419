import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-table-expanded-row {
    .ant-table-bordered {
      margin-block: 10px !important;
      margin-inline: 10px 10px !important;
    }
  }
  .ant-table-expanded-row:hover > .ant-table-cell {
    background: #2e3f5247;
  }
  .ant-table-expanded-row > .ant-table-cell {
    background: #2e3f5247;
  }
  .icon-container {
    display: flex;
    background: gainsboro;
    padding: 3px;
    border-radius: 10px;
    transition: 0.6s;
    &:hover {
      .cross-icon {
        display: block;
        transition: 0.6s !important;
      }
    }
  }
  .cross-icon {
    display: none;
    transition: 0.6s !important;
    position: absolute;
    right: 13px;
    top: -21px;
  }
  .ant-collapse {
    border: none;
    border-bottom: none !important;
    background: #ff000000;
    // padding: 10px 2px;
    .ant-collapse-item {
      border-bottom: none;
      button {
        span {
          color: #fff;
        }
      }
    }
    .ant-collapse-header {
      padding: 15px 10px;
    }
    .ant-collapse-content {
      border-top: 1px solid #d9d9d966;
    }
  }
  .sub-menu-list {
    max-height: 157px;
    overflow: auto;
  }
  .icon-view {
    background: white;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px dashed #05a0fb;
    cursor: pointer;
    transition: 0.6s;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }

  @keyframes highlight-border {
    0% {
      border-color: #0c1844;

      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
    }
    33% {
      border-color: #c80036;
    }
    66% {
      border-color: #ff6969;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
    }
    100% {
      border-color: #fff5e1;
    }
  }

  /* Class to apply the highlight animation */
  .highlight-border {
    border: 1px solid #fff;
    animation: highlight-border 1s;
    animation-iteration-count: 2; /* Repeat animation twice */
  }
  .childs-list {
    background: #edf1f5;
    padding: 12px;
    border-radius: 7px;
    margin-top: 10px;
  }

  .user-menu-demo {
    background-color: rgb(59, 113, 184) !important;
    padding: 10px;
    border-radius: 7px;
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }
  .ant-menu {
    background: transparent !important;
  }
  .ant-menu-submenu-title {
    flex-direction: row-reverse;
  }
  .ant-menu-sub {
    background: #00000033 !important;
  }
  .ant-menu-title-content {
    margin-inline-start: 0px !important;
  }
  .remove-icon {
    color: red;
  }
  .submenu-item {
    border-bottom: 1px solid #2e3f523d;
    margin-bottom: 20px;
  }
  #dynamic_form {
    position: relative;
  }
  .add-child-btn {
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
  }
  .ant-card-body {
    padding-bottom: 0px;
  }
  .child-list {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px dashed #2e3f52;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    flex-direction: row-reverse;
    transition: 0s;
    .ant-menu-item-icon,
    i {
      transition: 0s;
      color: #fff;
    }
    .ant-menu-title-content {
      transition: 0s !important;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 400;
    }
    &:hover {
      .ant-menu-item-icon,
      i {
        transition: 0s;
        color: #fff;
      }
      .ant-menu-title-content {
        transition: 0s !important;
        color: #fff;
      }
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      .ant-menu-item-icon,
      .ant-menu-title-content {
        color: #fff !important;
      }
    }
  }
  .ant-menu .ant-menu-item-selected {
    background-color: #00000000;
    .ant-menu-item-icon,
    i {
      color: #e6e12e !important;
    }
    .ant-menu-title-content {
      color: #e6e12e;
      font-size: 1.1rem;
      font-weight: 400;
    }
  }
  .ant-menu .ant-menu-submenu-arrow {
    inset-inline-end: 47px !important;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    padding-inline-end: 16px !important;
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      i,
      .ant-menu-title-content,
      .ant-menu-item-icon {
        color: #e6e12e !important;
      }
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .btn-primary {
    border-radius: 0.25rem !important;
    color: #3c71b8 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    font-size: small !important;
    .ki-outline {
      color: #3c71b8 !important;
    }
  }
`;
