import { Modal } from "antd";
import Icons from "../icons";
const { confirm } = Modal;

export const ErrorModal = (message) => {
  Modal.error({
    title: "Oops...",
    content: message || "Something went wrong",
    icon: <Icons type="cross" />,
  });
};

export const SuccessModal = (message) => {
  Modal.success({
    title: "Good job!",
    content: message || "",
    icon: <Icons type="checkright" />,
  });
};
export const OnOkDeleteModal = (message) => {
  Modal.success({
    title: "Deleted!",
    content: "Your file has been deleted.",
    icon: <Icons type="checkright" />,
  });
};
export const CancelDeleteModal = (message) => {
  Modal.error({
    title: "Cancelled",
    content: "Your imaginary file is safe :)",
    icon: <Icons type="cross" />,
  });
};

export const DeleteCModal = (message, action) => {
  confirm({
    title: "Are you sure?",
    icon: <Icons type="warning" />,
    content: message || `You Won't be able to revert this!`,
    okText: "Yes, delete it",
    okType: "danger",
    cancelText: "No, cancel",
    onOk() {
      action();
    },
    onCancel() {
      CancelDeleteModal();
    },
  });
};
