import { Badge } from "antd";
import { GetLinks } from "../../../config";

export default [
  {
    key: "8",
    icon: (
      <i className="ki-duotone ki-graph-up fs-1">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
        <span className="path6" />
      </i>
    ),

    label: (
      <>
        Metric Management
        <Badge className="ms-2" count={3} showZero color="#01c0c8" />
      </>
    ),
    children: [
      // {
      //   key: "/metric-management/pages",
      //   icon: (
      //     <span className="menu-bullet">
      //       {/* <span className="bullet bullet-dot" /> */}
      //       <i className="ki-duotone ki-book fs-1">
      //         <span className="path1" />
      //         <span className="path2" />
      //         <span className="path3" />
      //         <span className="path4" />
      //       </i>
      //     </span>
      //   ),
      //   label: GetLinks("/metric-management/pages", "Pages"),
      // },
      {
        key: "/metric-management/metric-groups",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-graph fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
            </i>
          </span>
        ),
        label: GetLinks("/metric-management/metric-groups", "Metric Groups"),
      },

      {
        key: "/metric-management/metrics",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-graph-2 fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        ),
        label: GetLinks("/metric-management/metrics", "Metrics"),
      },
      {
        key: "/metric-management/metric-mapping",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-graph-2 fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        ),
        label: GetLinks("/metric-management/metric-mapping", "Metric Mapping"),
      },
    ],
  },
];
