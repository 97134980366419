import React, { useEffect } from "react";
import { Wrapper } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import Edit from "./lib/edit";
import { MakeApiCall } from "../../../apis";
import { Button, Input, Table, message } from "antd";
import Pagination from "../../../components/pagination";
import { EditIcon, RenderTable, ViewIcon } from "../../../config";

const EmailDefinitions = () => {
  const history = useHistory();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [modalType, setModalType] = useState("new");

  const getList = async (data) => {
    const response = await MakeApiCall(
      `email-template?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_key=${data?.searchKey || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  const addRow = async (data) => {
    const response = await MakeApiCall(`email-template`, "post", data, true);

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setSelectedRow({});
      setEditMode(false);
    } else {
      message.warning(response?.message);
    }
  };
  const editRow = async (data) => {
    const response = await MakeApiCall(
      `email-template/${selectedRow?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setSelectedRow({});
      setEditMode(false);
    } else {
      message.warning(response?.message);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  const columns = [
    {
      title: "Sender Email Address",
      dataIndex: "sender_email_address",
      filterIndex: "sender_email_address",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      filterIndex: "slug",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      filterIndex: "subject",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      filterIndex: "tags",
      render: (e) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 260,
            }}
          >
            {e}
          </div>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (d) => (
        <div className="d-flex flex-shrink-0 ">
          <span
            onClick={() => {
              history.push(`/email-definitions?viewType=edit&id=${d?.id}`);
              // setSelectedRow(d);
              // setEditMode(true);
              // setModalType("edit");
            }}
            className="me-2"
          >
            {EditIcon}
          </span>
          <a
            onClick={() => {
              history.push(`/email-definitions?viewType=view&id=${d?.id}`);
              // setSelectedRow(d);
              // setEditMode(true);
              // setModalType("view");
            }}
            className="me-2"
          >
            {ViewIcon}
          </a>
        </div>
      ),
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ ...sortFilters, searchKey: "" });
  };

  const viewPage = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  if (viewPage) {
    return (
      <Edit
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        type={modalType}
        addRow={addRow}
        editRow={editRow}
        id={selectedID}
        viewPage={viewPage}
        close={() => {
          history.push(`/email-definitions`);
          // setSelectedRow({});
          // setEditMode(false);
          // setModalType("new");
        }}
      />
    );
  }
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <>
        <div className="row g-2">
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      className="w-250px"
                      allowClear
                      name="all_search"
                      onChange={(e) => {
                        setSearchKey((prev) => {
                          if (!e.target.value && prev) {
                            ClearSearch();
                          }
                          return e.target.value;
                        });
                      }}
                      value={searchKey}
                      placeholder="Search here..."
                    />
                    <button
                      onClick={() => {
                        setLoading(true);
                        getList({ ...sortFilters, searchKey: searchKey });
                      }}
                      className="btn btn-sm ms-3 btn-search"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="card-toolbar">
                  <a
                    onClick={() => {
                      history.push(`/email-definitions?viewType=add`);
                      // setSelectedRow({});
                      // setEditMode(true);
                      // setModalType("new");
                    }}
                    className="add-btn "
                  >
                    <svg
                      width={16}
                      height={16}
                      className="me-2"
                      fill="#ffffff"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                    </svg>
                    Add
                  </a>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                {/*begin::Table container*/}
                <Table
                  dataSource={list}
                  loading={loading}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  onChange={handleChange}
                  columns={columns?.map((d) => ({
                    ...d,
                    className: "minWidth-table",
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  }))}
                  bordered
                  size="small"
                />
                {/*end::Table container*/}
              </div>
              <div className="card-footer pt-0 pb-5">
                <Pagination
                  loading={loading}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize(e);
                    setPage(1);
                    getList({
                      page: 1,
                      pageSize: e,
                      searchKey: searchKey,
                      ...sortFilters,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage(e);
                    getList({
                      page: e,
                      searchKey: searchKey,
                      ...sortFilters,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

export default EmailDefinitions;
