import { Modal, Spin, message } from "antd";
import React, { useContext, useEffect, useState, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import { configModal, ORIGIN, urlDecode } from "../../../config";
import AdsProfiles from "./lib/ads-profiles";
import SpApi from "./lib/sp-api";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../apis";

const Setting = (props) => {
  const { pageTitle } = props;
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const location = useLocation();
  const [sPList, setSPList] = useState([]);
  const [advertisingList, setAdvertisingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const [selectedTabs, setSelectedTabs] = useState(
    location?.pathname?.includes("spapi-callback") ? "1" : "2"
  );
  const [adList, setAdList] = useState([]);
  const [adListModal, setAdListModal] = useState(false);

  const contextValue = useContext(GlobalContext);
  const [lws, setLws] = useState(0);
  const [sp, setSp] = useState(0);
  // const { lws, sp } = contextValue?.data?.credentialsStatus;
  const { credentials, user_ } = contextValue?.data;

  const [locationData, setLocationData] = useState({});

  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=6`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  const messageView = async (title, message) => {
    const confirmed = await modal.warning(configModal(title, message));
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  useEffect(() => {
    message.destroy();
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(credentials?.advertising_client_id);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);
    return () => {};
  }, []);

  const getListCheck = async () => {
    try {
      const obj = {
        sp: parseInt(
          JSON.parse(localStorage.getItem("user"))?.amazon_connected
        ),
        lws: parseInt(
          JSON.parse(localStorage.getItem("user"))?.amazon_ads_connected
        ),
      };

      contextValue?.updateCommonGlobalVal({
        credentialsStatus: obj,
      });
    } catch (error) {
      message.warning(error?.message);
    }
  };
  const AdsGenerateRefreshToken = async (data) => {
    const response = await MakeApiCall(
      `${
        localStorage.getItem("reset")
          ? "user-credential/reset-refresh-ad-token"
          : "user-credential/generate-refresh-ad-token"
      }`,

      "post",
      data,
      true
    );
    try {
      if (response?.status) {
        message.destroy();
        await modal.success(configModal("Success", response?.message));
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("user")),
            amazon_ads_connected: 1,
          })
        );
        // setAdList(JSON.parse(response?.data));
        // setAdListModal(true);
        setTimeout(() => {
          window.location.assign(
            localStorage.getItem("currantUrl") || "/setting/spapi-callback"
          );
        }, 2000);
      } else {
        message.destroy();
        messageView("Warning", response?.message);

        setTimeout(() => {
          window.location.assign(
            localStorage.getItem("currantUrl") || "/setting/spapi-callback"
          );
        }, 2000);
      }
    } catch (error) {
      message.destroy();
      messageView("Error", "Something went wrong");
    }
  };
  const GenerateSPRefreshToken = async (data) => {
    const response = await MakeApiCall(
      `${
        localStorage.getItem("reset")
          ? "user-credential/reset-refresh-token"
          : "user-credential/generate-refresh-token"
      }`,
      "post",
      data,
      true
    );
    if (response?.status) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          amazon_connected: 1,
          amazon_seller_id: data?.selling_partner_id || "",
        })
      );
      localStorage.removeItem("reset");
      message.destroy();
      await modal.success(configModal("Success", response?.message));

      setTimeout(() => {
        window.location.assign(
          localStorage.getItem("currantUrl") || "/setting/spapi-callback"
        );
      }, 2000);
    } else {
      localStorage.removeItem("reset");
      messageView("Warning", response?.message);
      message.warning(response?.message);
      setTimeout(() => {
        window.location.assign(
          localStorage.getItem("currantUrl") || "/setting/spapi-callback"
        );
      }, 2000);
    }
  };
  useEffect(() => {
    if (location.pathname.includes("/callbackads")) {
      setSelectedTabs("2");
      if (location.search) {
        message.destroy();
        message.loading("Loading...", 0);
        const { code, marketplace_id } = urlDecode(location);

        const obj = {
          ad_code: code,
          marketplace_id: marketplace_id || "ATVPDKIKX0DER",
        };

        AdsGenerateRefreshToken(obj);
      } else {
        message.destroy();
      }
    } else if (location.pathname.includes("/spapi-callback")) {
      setSelectedTabs("1");
      if (location?.search) {
        const { selling_partner_id, spapi_oauth_code, state } =
          urlDecode(location);

        const dataView = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          seller_name: state?.split("!!")?.[1] || "",
          marketplace_id: state?.split("!!")?.[2] || "ATVPDKIKX0DER",
          other_marketplace_ids: [],
        };
        message.destroy();
        message.loading("Loading...");

        GenerateSPRefreshToken(dataView);
      }
    }
  }, [location]);
  const config = {
    title: "Connections",
    content: (
      <>
        <div>
          It appears that you have not yet connected your Sellercentral account
          to SIP. Please connect to Sellercentral before connecting to your
          Advertising account.
        </div>
      </>
    ),
  };

  const getList = async () => {
    setLoadingList(true);
    const response = await MakeApiCall(`user-credentials`, "get", null, true);

    if (response?.status) {
      setSp(
        response?.data?.filter((d) => d?.credential_type !== "Advertising-API")
          ?.length === 0
          ? 0
          : 1
      );
      setLws(
        response?.data?.filter((d) => d?.credential_type === "Advertising-API")
          ?.length === 0
          ? 0
          : 1
      );

      setAdvertisingList(
        response?.data?.filter((d) => d?.credential_type === "Advertising-API")
      );
      setSPList(response?.data);

      setLoading(false);
      setLoadingList(false);
    } else {
      // window.location.replace("/spapi-callback");
      message.warning(response?.message);
      setLoading(false);
      setLoadingList(false);
    }
  };

  useEffect(() => {
    if (
      (location.pathname.includes("/callbackads") ||
        location.pathname.includes("/spapi-callback")) &&
      location.search
    ) {
    } else {
      setLoading(true);
      getList();
    }

    return () => {};
  }, []);

  const openModal = async () => {
    const confirmed = await modal.confirm(config);
    if (confirmed) {
      return window?.open(
        `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
          credentials?.lwa_application_id
        }&state=${user_?.email}!!${user_?.name.replace(
          /\s/g,
          ""
        )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
      );
    }
  };

  const LWS = (
    <div className="card pb-5" style={{ borderBottom: "1px solid #ccc" }}>
      <div className="card-body px-10">
        <h5 className="primaryHeading2 text-right">
          Connect Your PPC Accounts
        </h5>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 pl-4 px-10">
          <div className="why-text">
            Why Connect to your Amazon PPC Account?
          </div>
          <ul className="content-layout">
            <li>Connect your Amazon PPC account to ArgoMetrix trusted SIP.</li>
            <li>
              Access a 360-degree view of your Amazon performance with ad
              spending.
            </li>
            <li>
              Analyze your paid, organic, and external traffic clicks and costs.
            </li>
            <li>Monitor your ACOS and TACOS both at the item and top level.</li>
            <li>
              Make data-driven decisions with your campaigns and listing
              optmization
            </li>
          </ul>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 pl-5">
          <div className="connect-amazon-ads">
            <div className="amazon-logo">
              <img
                height="55px"
                width="250px"
                style={{ objectFit: "contain" }}
                src="https://static.wixstatic.com/media/e61c50_8be22736399d4eb8b11d650054c4472e~mv2.webp"
                alt="connect-image"
              />
            </div>
            <br />
            <div className="amazon-btn">
              <a
                style={{ paddingLeft: 39 }}
                onClick={() => {
                  if (sp !== 1) {
                    openModal();
                    return;
                  }

                  let options = {};
                  options.scope = "advertising::campaign_management";
                  options.response_type = "code";
                  window.amazon.Login.authorize(
                    options,
                    `${
                      credentials?.advertising_return_url || ""
                    }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                      user_?.seller_name || ""
                    }`
                  );
                }}
              >
                <button className="connect-button">
                  Connect to <b>Amazon</b>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const SP = (
    <div className="card pb-5" style={{ borderBottom: "1px solid #ccc" }}>
      <div className="card-body px-10">
        <h5 className="primaryHeading2 text-right">
          Connect Your Seller Accounts
        </h5>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 pl-4 px-10">
          <div className="why-text">Why Connect to Amazon?</div>
          <ul className="content-layout">
            <li>
              ArgoMetrix is a trusted platform by Amazon and Amazon sellers.
            </li>
            <li>
              When connected, we can automate data exchange to maximize your
              selling performance.
            </li>
            <li>
              ArgoMetrix is an authority in online retail analytics and provides
              competitive intelligence.
            </li>
            <li>
              Connecting to Amazon makes it easy to manage your data and track
              performance metrics.
            </li>
          </ul>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 pl-5">
          <div className="connect-amazon">
            <div className="amazon-logo">
              <img
                height="100px"
                width="170px"
                style={{ objectFit: "contain" }}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1024px-Amazon.com-Logo.svg.png"
                alt="connect-image"
              />
            </div>
            <div className="amazon-btn">
              <a
                onClick={() => {
                  window?.open(
                    `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
                      credentials?.lwa_application_id
                    }&state=${user_?.email}!!${user_?.name.replace(
                      /\s/g,
                      ""
                    )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
                  );
                }}
              >
                <button className="connect-button">
                  Connect to <b>Amazon</b>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid position-relative"
    >
      <Spin spinning={loadingList} tip="Loading...">
        <>
          {sp === 0 || lws === 0 ? (
            <div className="row mb-2">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {sp === 0 && SP}
                <br />
                {lws === 0 && LWS}
              </div>
            </div>
          ) : (
            <></>
          )}
          {(sp !== 0 || lws !== 0) && (
            <SpApi
              getList={() => {
                setLoading(true);
                getList();
              }}
              locationData={locationData}
              getListCheck={getListCheck}
              loading={loading}
              sPList={sPList}
              pageTitle={pageTitle}
            />
          )}
          {adListModal && (
            <AdsProfiles
              show={adListModal}
              saveProfile={"user-credential/save-ad-profile"}
              data={adList}
              AdsCallBack={"/callbackads"}
              getList={() => {
                setLoading(true);
                getList();
              }}
              onHide={() => {
                setAdListModal(false);
              }}
            />
          )}
          {contextHolder}
          <div id="amazon-root" />
        </>
      </Spin>
    </Wrapper>
  );
};

export default Setting;
