import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./modules/auth";
import Page from "./modules/page";

import AdminLogin from "./modules/auth/admin-login";
import ForgotPasswordAdmin from "./modules/auth/lib/forgot-password.admin";
import ForgotPasswordSeller from "./modules/auth/lib/forgot-password.seller";
import SellerSignUp from "./modules/auth/lib/seller-sign-up";
import UserVerify from "./modules/auth/lib/user-verify";

function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense>
        <Switch>
          <Route path="/seller/login" component={Login} exact />
          <Route path="/admin/login" component={AdminLogin} exact />
          <Route path="/user-verify" component={UserVerify} exact />
          {/* <Route path="/partner-sign-up" component={SignUp} exact /> */}
          <Route path="/seller-sign-up" component={SellerSignUp} exact />
          <Route
            path="/forgot-password"
            component={ForgotPasswordSeller}
            exact
          />
          <Route
            path="/seller-reset-password"
            component={ForgotPasswordSeller}
            exact
          />
          <Route
            path="/admin/forgot-password"
            component={ForgotPasswordAdmin}
            exact
          />
          <Route
            path="/admin-reset-password"
            component={ForgotPasswordAdmin}
            exact
          />
          <Route path="/" component={Page} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRouter;
