import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../common-context";
import { Wrapper } from "../style";
import { Breadcrumb, Spin, Table, message } from "antd";
import CredentialsError from "../../../credential-page";
import { MakeApiCall } from "../../../../apis";
import moment from "moment";
import InfoVideo from "../../../../components/header-icons";

const SellerProfile = ({ menusList, pageTitle }) => {
  const contextValue = useContext(GlobalContext);
  const { user_ } = contextValue?.data;

  const [userProfile, setUserProfile] = useState({
    amazon_seller_id: null,
    created_at: null,
    company_name: null,
    city: null,
    state: null,
    zip_code: null,
    country: null,
    coupon_code_id: null,
  });

  const [activityList, setActivityList] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);

  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=9`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const getUserActivities = async () => {
    setActivityLoading(true);
    const response = await MakeApiCall(
      `seller/profile/${user_?.seller_id}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setActivityLoading(false);
      setActivityList(response?.data?.userActivity || []);

      setUserProfile({
        amazon_seller_id: response?.data?.amazon_seller_id,
        created_at: response?.data?.created_at,
        company_name: response?.data?.company_name,
        city: response?.data?.city,
        state: response?.data?.state,
        zip_code: response?.data?.zip_code,
        country: response?.data?.country,
        coupon_code_id: response?.data?.coupon_code_id,
      });
    } else {
      setActivityLoading(false);
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getUserActivities();
    return () => {};
  }, []);

  const columns = [
    {
      title: "Activity",
      dataIndex: ["event_type_data", "event_message"],
      key: "activity_type",
      width: 200,
    },
    // {
    //   title: "Date and Time (ET)",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (e) => {
    //     return moment
    //       .utc(new Date(parseInt(e) * 1000))
    //       .tz("America/New_York")
    //       .format("MM/DD/YYYY hh:mm A");
    //   },
    // },
    {
      title: "Updated at (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (e) => {
        return moment
          .utc(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
  ];

  const updateProfile = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `seller-user/update-profile/${contextValue?.data?.user_?.seller_id}`,
      "put",
      { ...userProfile, seller_id: contextValue?.data?.user_?.seller_id },
      true
    );

    if (response?.status) {
      contextValue.updateCommonGlobalVal({
        ...JSON.parse(localStorage.getItem("user")),
        ...response?.data,
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          ...response?.data,
        })
      );
      message.destroy();
      message.success(response?.message || "Profile updated successfully");
    } else {
      getUserActivities();
      message.destroy();
      message.warning(response?.message || "Profile updation failed");
    }
  };

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "seller-profile")?.is_restricted === 1
  ) {
    return <CredentialsError type="seller-profile" />;
  }

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid position-relative"
    >
      {/* <h5
        class="primaryHeading2 d-flex justify-content-start mt-2 my-3"
        style={{ position: "absolute", top: "-28px", left: "246px" }}
      ></h5> */}
      <div className="row mt-3">
        {/*begin::Sidebar*/}
        <div className="col-xl-4 col-lg-6 col-md-12 ">
          {/*begin::Card*/}

          <div
            className="card mb-5 mb-xl-8"
            style={{
              BorderTop: "2px solid #4318ff",
              position: "sticky",
              top: 45,
            }}
          >
            <div className="card-header py-1 mh-25px ">
              <div className="card-title">
                {pageTitle}
                <InfoVideo
                  data={locationData}
                  title="seller_profile"
                  className={"ms-3"}
                />
              </div>
            </div>
            <div
              className="card-body pt-0"
              style={{ maxHeight: "726px", overflow: "auto" }}
            >
              <div className="pb-0 fs-6">
                <div className="fw-bold ">Amazon Seller ID</div>
                <div className="text-gray-600">
                  {userProfile?.amazon_seller_id || "-"}
                </div>

                <div className="separator separator-dashed my-4" />

                <div className="fw-bold ">Create At (ET)</div>
                <div className="text-gray-600">
                  {moment
                    .utc(new Date(parseInt(userProfile?.created_at) * 1000))
                    .tz("America/New_York")
                    .format("MM/DD/YYYY")}
                </div>
              </div>
              <div className="mt-5">
                <Table
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  loading={activityLoading}
                  size="small"
                  pagination={true}
                  scroll={{ x: "max-content" }}
                  columns={columns}
                  dataSource={activityList?.sort((b, a) => {
                    return Number(a.updated_at) - Number(b.updated_at);
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-6 col-md-12  ">
          {/*begin::Row*/}
          <div className="row gy-5 g-xl-5">
            {/*begin::Col*/}
            <div className="col-xl-12">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Seller Details</h3>
                  </div>
                </div>

                <Spin tip="Loading..." spinning={activityLoading}>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <div id="kt_account_profile_details_form" className="form">
                      <div className="card-body border-top border-top-dashed p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Amazon Seller ID
                          </label>

                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-12 fv-row">
                                <input
                                  type="text"
                                  disabled
                                  name="fname"
                                  className="form-control  mb-3 mb-lg-0"
                                  placeholder="Amazon Seller ID"
                                  value={userProfile?.amazon_seller_id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Seller Company Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="Seller Company Name"
                              value={userProfile?.company_name}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  company_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            City
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="City"
                              value={userProfile?.city}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  city: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            State/Zip
                          </label>
                          <div className="col-lg-4 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="State"
                              value={userProfile?.state}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  state: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-4 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="Zip"
                              value={userProfile?.zip_code}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  zip_code: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Country
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="Country"
                              value={userProfile?.country}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  country: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Coupon code
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="company"
                              className="form-control "
                              placeholder="Coupon code"
                              value={userProfile?.coupon_code_id}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                        <button
                          type="reset"
                          className="btn btn-light btn-active-light-primary me-2 fs-7"
                        >
                          Discard
                        </button>
                        <button
                          onClick={() => {
                            updateProfile();
                          }}
                          className="btn btn-primary  fs-7"
                          id="kt_account_profile_details_submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SellerProfile;
