import { GetLinks } from "../../../config";

export default [
  {
    key: "/manage-seller",
    icon: (
      <i className="ki-duotone ki-people fs-1">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
      </i>
    ),
    label: GetLinks("/manage-seller", "Manage Seller"),
  },
];
