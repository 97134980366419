import React from "react";
import { Wrapper } from "./style";
import { Button, Result } from "antd";

const ManageBilling = () => {
  return (
    <Wrapper
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        style={{ height: "100vh" }}
        extra={<Button type="primary">Back Home</Button>}
      />
    </Wrapper>
  );
};

export default ManageBilling;
