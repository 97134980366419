import { GetLinks } from "../../../config";

export default [
  {
    key: "/seller-resources",
    icon: (
      <i className="ki-duotone ki-purchase fs-1">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    label: GetLinks("/seller-resources", "Seller Resources"),
  },
];
