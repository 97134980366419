import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Popconfirm, Select, Table, Tooltip, message } from "antd";
import { DeleteIcon, RenderTable } from "../../../config";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import moment from "moment";

const AdminAlert = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [alertLoading, setAlertLoading] = useState(true);
  const [alertList, setAlertList] = useState([]);

  const [sellerList, setSellerList] = useState([]);
  const [sellerLoading, setSellerLoading] = useState(true);

  const [selectedFilters, setSelectedFilters] = useState({
    seller_name: null,
    event_type_id: null,
  });

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });

  const GetAlerts = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `admin-alert?is_deleted=0&page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&seller_name=${data?.seller_name || ""}&event_type_id=${
        data?.event_type_id || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
      setList(response?.data?.records || []);
    } else {
      message.destroy();
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const UpdateAlerts = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `admin-alert/${id}`,
      "put",
      {
        is_deleted: 1,
      },
      true
    );

    if (response?.status) {
      message.destroy();
      GetAlerts({ ...sortFilters, ...selectedFilters });
      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.message);
    }
  };
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.dataIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    GetAlerts({ ...obj, page: 1, pageSize: pageSize, ...selectedFilters });
  };

  const GetAlertsTypeList = async () => {
    setAlertLoading(true);
    const response = await MakeApiCall(
      `seller-tracking/event-types?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setAlertLoading(false);
      setAlertList(response?.data?.records || []);
    } else {
      message.destroy();
      setAlertLoading(false);
      message.warning(response?.message);
    }
  };

  const GetSellerList = async () => {
    setSellerLoading(true);
    const response = await MakeApiCall(
      `seller-user?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSellerLoading(false);
      setSellerList(response?.data?.records || []);
    } else {
      message.destroy();
      setSellerLoading(false);
      message.warning(response?.message);
    }
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (e, row) => {
        return (
          <Popconfirm
            title="Delete the Alerts"
            description={
              <span>
                Are you sure to delete this{" "}
                <b>{row?.event_type_data?.event_name}?</b>
              </span>
            }
            okText="Yes"
            onConfirm={() => UpdateAlerts(e)}
            placement="left"
            cancelText="No"
          >
            <div style={{ textAlign: "center", cursor: "pointer" }}>
              {DeleteIcon}
            </div>
          </Popconfirm>
        );
      },
    },

    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
      ...PropsFilter("email"),
      render: (user) => `${user}`,
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      ...PropsFilter("seller_name"),
      render: (e) => {
        return <span>{e}</span>;
      },
    },

    {
      title: "Alert Type",
      dataIndex: ["event_type_data", "event_name"],
      key: "alert_type",
    },
    {
      title: "Alert Message",
      dataIndex: ["event_type_data", "event_message"],
      key: "alert_message",
    },
    {
      title: "Created At (ET)",
      align: "left",
      filterIndex: "created_at",
      ...PropsFilter("created_at"),
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },

    {
      title: "Updated At (ET)",
      align: "left",
      filterIndex: "updated_at",
      ...PropsFilter("updated_at"),
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.updated_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    GetAlertsTypeList();
    GetSellerList();
    GetAlerts({ ...sortFilters, ...selectedFilters });
    return () => {};
  }, []);

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <Select
              loading={sellerLoading}
              options={sellerList?.map((d) => ({
                label: d?.seller_name,
                value: d?.seller_name,
              }))}
              value={selectedFilters?.seller_name}
              onChange={(e) => {
                GetAlerts({
                  page: page,
                  pageSize: pageSize,
                  ...sortFilters,
                  ...selectedFilters,
                  seller_name: e,
                });
                setSelectedFilters({
                  ...selectedFilters,
                  seller_name: e,
                });
              }}
              className="w-150px me-3"
              placeholder="Select Seller"
              allowClear
            />
            <Select
              className="w-225px"
              placeholder="Select Alert Type"
              loading={alertLoading}
              value={selectedFilters?.event_type_id}
              allowClear
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,
                  event_type_id: e,
                });
                GetAlerts({
                  page: page,
                  pageSize: pageSize,
                  ...sortFilters,
                  ...selectedFilters,
                  event_type_id: e,
                });
              }}
              options={alertList?.map((d) => ({
                label: d?.event_name,
                value: d?.id,
              }))}
            />
          </div>
        </div>
        <div className="card-body pt-0">
          <Table
            pagination={false}
            scroll={{ x: "max-content" }}
            onChange={handleChange}
            loading={loading}
            bordered
            columns={columns?.map((d) => ({
              ...d,
              className: "minWidth-table",
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
            size="small"
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            dataSource={list}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
          />
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              GetAlerts({
                page: 1,
                pageSize: e,
                ...sortFilters,
                ...selectedFilters,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              GetAlerts({
                page: e,
                ...sortFilters,
                ...selectedFilters,
              });
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminAlert;
