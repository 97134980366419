import { GetLinks } from "../../../config";

export default [
  {
    key: "/email-definitions",
    icon: (
      <i className="ki-duotone ki-sms fs-1">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    label: GetLinks("/email-definitions", "Email Definitions"),
  },
];
