import { Image, Input, message, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { TableLoading } from "../../../components/table-animation";
import Pagination from "../../../components/pagination";
import { DeleteIcon, RenderTable } from "../../../config";
import moment from "moment";
import { MakeApiCall } from "../../../apis";

export default function ArchiveUsers({ onCall, deleteReload }) {
  const [searchKey, setSearchKey] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const getUser = async (data) => {
    const response = await MakeApiCall(
      `seller-user/archived?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_key=${data?.searchKey || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setUserList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setUserList([]);
    }
  };

  const ClearSearch = async () => {
    setLoading(true);

    await getUser({ ...sortFilters, searchKey: "" });
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });

  const deleteUser = async (id) => {
    const response = await MakeApiCall(
      `seller-user/${id}`,
      "delete",
      null,
      true
    );
    if (response?.status) {
      message.success(response?.message);
      setLoading(true);
      getUser({ ...sortFilters, searchKey: searchKey });
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };
  const restoreUser = async (id) => {
    const response = await MakeApiCall(
      `seller-user/restore/${id}`,
      "put",
      null,
      true
    );
    if (response?.status) {
      message.success(response?.message);
      setLoading(true);
      onCall();
      getUser({ ...sortFilters, searchKey: searchKey });
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getUser({ ...obj, searchKey: searchKey });
  };

  useEffect(() => {
    setLoading(true);
    getUser();
  }, [deleteReload]);

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: "Seller Name",
      key: "seller_name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      align: "left",
      render: (e) => {
        return <span>{e}</span>;
      },
    },
    {
      title: "User Name",
      align: "left",
      filterIndex: "name",
      render: (d) => {
        return (
          <div className="d-flex align-items-center">
            {d?.photo ? (
              <Image src={d?.photo} width={45} height={45} className="" />
            ) : (
              <div className="symbol symbol-circle symbol-50px overflow-hidden ">
                <a href>
                  <div
                    className="symbol-label fs-3 fw-bolder bg-light-danger text-danger"
                    style={{ textTransform: "capitalize" }}
                  >
                    {d?.name?.[0]}
                  </div>
                </a>
              </div>
            )}

            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column ms-3">
              <a
                href
                className="text-gray-800 text-hover-primary mb-1 fw-bolder"
              >
                {d?.name || "-"}
              </a>
              <span>{d?.email || "-"}</span>
            </div>
          </div>
        );
      },
    },

    {
      title: "Created At (ET)",
      align: "left",
      filterIndex: "created_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Updated At (ET)",
      align: "left",
      filterIndex: "updated_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.updated_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        return (
          <div className="   d-flex justify-content-center  ">
            <div className="me-3">
              <Popconfirm
                title="Are you sure you wish to continue?"
                placement="left"
                cancelText="No"
                // getPopupContainer={(target) =>
                //   document.getElementById("table-view").parentNode
                // }
                okText="Yes"
                onConfirm={() => {
                  restoreUser(text?.seller_id);
                }}
              >
                <svg
                  width={24}
                  className="ms-2 cursor-pointer"
                  height={24}
                  fill="#065ad8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.942 3.791c.3.3.3.785 0 1.084L6.184 6.633H14.3a6.9 6.9 0 1 1 0 13.8H8.167a.767.767 0 0 1 0-1.533H14.3a5.367 5.367 0 1 0 0-10.733H6.184l1.758 1.758a.767.767 0 0 1-1.084 1.084L3.79 7.942c-.3-.3-.3-.785 0-1.084L6.858 3.79c.3-.3.785-.3 1.084 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </Popconfirm>
            </div>
            {text?.id !== 1 && (
              <Popconfirm
                title="This seller and its data will be removed from the system. This action is not reversible."
                placement="left"
                cancelText="No"
                // getPopupContainer={(target) =>
                //   document.getElementById("table-view").parentNode
                // }
                okText="Yes"
                onConfirm={() => {
                  deleteUser(text?.seller_id);
                }}
              >
                <span style={{ cursor: "pointer" }} className=" text-danger">
                  {DeleteIcon}
                </span>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="card mt-5">
      <div className="card-header">
        <div className="card-title">
          <span className="me-3">Archived Sellers</span>
          <div className="position-relative mr-4">
            <Input
              type="text"
              className="w-250px"
              allowClear
              name="all_search"
              placeholder="Search by name or email..."
              onChange={(e) => {
                setSearchKey((prev) => {
                  if (!e.target.value && prev) {
                    ClearSearch();
                  }
                  return e.target.value;
                });
              }}
              value={searchKey}
            />
            <button
              onClick={() => {
                setLoading(true);
                getUser({ ...sortFilters, searchKey: searchKey });
              }}
              className="btn btn-sm ms-3 btn-search"
            >
              Search
            </button>
          </div>
        </div>
        <div className="card-toolbar"></div>
      </div>
      <div className="card-body pt-0 " id="table-view">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : (
          <Table
            dataSource={userList}
            onChange={handleChange}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            columns={columns?.map((d) => ({
              ...d,
              className: "minWidth-table",
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
            scroll={{ x: "max-content" }}
            pagination={false}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
          />
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getUser({
              page: 1,
              pageSize: e,
              ...sortFilters,
              searchKey: searchKey,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getUser({
              page: e,
              searchKey: searchKey,
              ...sortFilters,
            });
          }}
        />
      </div>
    </div>
  );
}
