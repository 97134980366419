import { GetLinks } from "../../../config";

export default [
  {
    key: "/dashboard",
    icon: (
      <i className="ki-duotone ki-chart-pie-simple fs-1">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    label: GetLinks("/dashboard", "Dashboard"),
  },
];
