import styled from "styled-components";

export const Wrapper = styled.div`
  label {
    font-weight: 500;
    color: #6c757d;
    font-family: "Rubik", sans-serif;
    font-size: 12px !important;
  }
  color: #6c757d;
  h5,
  .h5 {
    font-size: 16px !important;

    font-family: inherit !important;
    font-weight: 500 !important;
  }
  .btn-info {
    color: #fff;
    background-color: #009efb;
    border-color: #009efb;
  }
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
`;

export const EditWrapper = styled.div`
  .form-control {
    color: #212529;
    min-height: 38px;
    display: initial;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-horizontal label {
    margin-bottom: 0px;
  }
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .text-right {
    text-align: right !important;
  }
`;
