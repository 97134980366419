import { Alert, Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../apis";

import { Wrapper } from "../style";
import { LOGINURL } from "../../../config";

const ForgotPasswordSeller = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [resetLoading, setResetLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [errorView, setErrorView] = useState({
    show: false,
    message: "",
    type: "",
  });

  const submit = async (values) => {
    setSubmitLoading(true);
    const response = await MakeApiCall("seller/forgot-password", "post", {
      email,
    });
    if (response?.status) {
      setSubmitLoading(false);

      setErrorView({
        show: true,
        message: response?.message,
        type: "success",
      });

      setTimeout(() => {
        window.location.replace(LOGINURL);
      }, 2000);
    } else {
      setSubmitLoading(false);

      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
    }
  };

  const resetPassword = async (data) => {
    setResetLoading(true);
    const response = await MakeApiCall(
      `seller/reset-password?token=${data?.token}`,
      "put",
      {
        password: data?.password,
        confirm_password: data?.confirm_password,
      },
      true
    );
    if (response?.status) {
      setResetLoading(false);
      setErrorView({
        show: true,
        message: response?.message,
        type: "success",
      });
      form.resetFields();
      setTimeout(() => {
        window.location.replace(LOGINURL);
      }, 2000);
    } else {
      setResetLoading(false);

      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
    }
  };

  const onFinish = (values) => {
    resetPassword({
      ...values,
      token: location?.search?.replace("?token=", ""),
    });
  };

  if (location?.pathname === "/seller-reset-password") {
    return (
      <Wrapper
        style={{ backgroundColor: "#065ad8" }}
        className="d-flex flex-column flex-root"
      >
        <div className="new-ui-reset-password">
          <h4 className="mb-10">Forgot Password</h4>
          {errorView?.show && (
            <Alert
              message={errorView?.message}
              type={errorView?.type}
              closable
              className="mb-5"
              afterClose={() => {
                setErrorView({ show: false, message: "" });
              }}
            />
          )}
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <input
              type="hidden"
              name="c_is_active"
              value="d47b2215fdf1f943253101aa177608b4"
            />

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Repeat Password" />
            </Form.Item>

            <Form.Item>
              <Button loading={resetLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Link to="/sign-in" className="btn btn-lg btn-link fw-bold py-0">
            Back
          </Link>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      style={{ backgroundColor: "#065ad8" }}
      className="d-flex flex-column flex-root "
    >
      <div className="new-ui-reset-password">
        <h4 className="mb-10">Forgot Password</h4>
        {errorView?.show && (
          <Alert
            message={errorView?.message}
            type={errorView?.type}
            closable
            className="mb-5"
            afterClose={() => {
              setErrorView({ show: false, message: "" });
            }}
          />
        )}
        <div className="fv-row mb-7 fv-plugins-icon-container">
          <Input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            size="large"
            type="email"
            placeholder="Email"
            name="email"
            autoComplete="off"
            data-kt-translate="password-reset-input-email"
          />
          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
        </div>
        <button
          id="kt_password_reset_submit"
          onClick={() => {
            submit();
          }}
          className="btn btn-primary me-2"
        >
          {!submitLoading && <span className="indicator-label">Submit</span>}
          {submitLoading && (
            <span className="">
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
          {/*end::Indicator progress*/}{" "}
        </button>
        <Link to="/sign-in" className="btn btn-lg btn-link fw-bold mt-3">
          Back
        </Link>
      </div>
    </Wrapper>
  );
};

export default ForgotPasswordSeller;
