import React, { useState } from "react";
import { Wrapper } from "./style";
import Add from "./lib/add";
import { Table, Button, Badge, Input } from "antd";
import { EditIcon } from "../../../../config";

const VersionTracking = () => {
  const [addMode, setAddMode] = useState(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      key: "id",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: () => (
        <div className="d-flex justify-content-center flex-shrink-0 ">
          {EditIcon}
        </div>
      ),
    },
    {
      title: "Version number",
      dataIndex: "versionNumber",
      key: "versionNumber",
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Release Note URL",
      dataIndex: "releaseNoteUrl",
      key: "releaseNoteUrl",
      render: (text) => <a href={text}>Release Note</a>,
    },
    {
      title: "Actual Release Date",
      dataIndex: "actualReleaseDate",
      key: "actualReleaseDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Badge status="success" text={text} />,
    },
  ];

  const data = [
    {
      key: "1",
      id: 101,
      versionNumber: "3.1.1",
      module: "Admin Portal",
      releaseNoteUrl: "#",
      actualReleaseDate: "10-01-2024",
      status: "Active",
    },
    // ... other data entries
  ];

  if (addMode) {
    return <Add close={() => setAddMode(false)} />;
  }
  return (
    <Wrapper id="kt_content_container" className="custom-ui container-fluid">
      <>
        <div className="row g-2 mt-2">
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header  ">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      className="w-250px"
                      allowClear
                      name="all_search"
                      placeholder="Search here..."
                    />
                  </div>
                  <button className="btn btn-sm ms-3 btn-search">Search</button>
                </div>
                <div className="card-toolbar">
                  <div className="gap-4 d-flex">
                    <button className="btn  fs-7 btn-light-primary fw-bold">
                      CSV
                    </button>
                    <button className="btn  fs-7 btn-light-primary fw-bold">
                      PDF
                    </button>
                    <button className="btn  fs-7 btn-icon btn-light-primary fw-bold">
                      <i className="ki-outline ki-printer fs-2" />
                    </button>

                    <a
                      onClick={() => setAddMode(true)}
                      className="add-btn ms-3"
                    >
                      <svg
                        width={16}
                        height={16}
                        className="me-2"
                        fill="#ffffff"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                      </svg>
                      Add version
                    </a>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0 py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}

                  <Table
                    scroll={{ x: "max-content" }}
                    columns={columns}
                    dataSource={data}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    bordered
                    size="small"
                    pagination={false}
                  />
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              <div className="card-footer pt-0 pb-5">
                <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                  <div className="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                    <select
                      name
                      aria-controls
                      className="form-select form-select-sm form-select-solid w-75px me-3"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    Showing 0 to 0 of 0 entries
                  </div>
                  <ul className="pagination">
                    <li className="page-item previous">
                      <a href="#" className="page-link">
                        <i className="previous" />
                      </a>
                    </li>
                    <li className="page-item active">
                      <a href="#" className="page-link">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        5
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        6
                      </a>
                    </li>
                    <li className="page-item next">
                      <a href="#" className="page-link">
                        <i className="next" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

export default VersionTracking;
