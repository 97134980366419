import { Button, Form, Select, Spin, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const { Option, OptGroup } = Select;

const AddCaralog = ({
  setVisible,
  setList,
  pageType,
  id,
  catalogList,
  catalogLocationList,
  catalogLoading,
  catalogLocationLoading,
  getCatalogList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const [metricList, setMetricList] = useState([]);
  const [metricLoading, setMetricLoading] = useState(true);

  const [selectedType, setSelectedType] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);

  const addAccessMapping = async (data) => {
    const response = await MakeApiCall(
      `content-access-mapping${
        Object.keys(selectedRow)?.length === 0 ? "" : `/${selectedRow?.id}`
      }`,
      Object.keys(selectedRow)?.length === 0 ? "post" : "put",
      data,
      true
    );

    if (response?.status) {
      setList((prev) => {
        let found = false;
        const newList = prev.map((item) => {
          if (item.id === response.data.id) {
            found = true; // Mark as found and update the item
            return response.data;
          }
          return item; // Return the item unmodified if not matched
        });

        if (!found) {
          // If the item wasn't found in the list, add it
          return [...newList, response.data];
        }

        // Return the updated list if the item was found and updated
        return newList;
      });
      setLoading(false);
      form.resetFields();
      setVisible(false); // Close modal after form submission
    } else {
      setLoading(false);
      message.warning(
        Object.values(response?.data || {})?.[0] || response?.message
      );
    }
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `content-access-mapping/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const { metric_id, location_id, content_catalog_id } = response?.data;
      if (metric_id) {
        setSelectedType(true);
        getCatalogList("", true, metric_id);
        form.setFieldsValue({
          metric_id: metric_id,
          content_catalog_id: content_catalog_id,
        });
      } else {
        form.setFieldsValue({
          location_id: location_id,
          content_catalog_id: content_catalog_id,
        });
        setSelectedType(false);
        getCatalogList(location_id, true);
      }
      setSelectedRow(response?.data || {});

      setPageLoading(false);
    } else {
      setPageLoading(false);
      setSelectedRow({});
    }
  };

  const getMetricList = async () => {
    const response = await MakeApiCall(`metric?isAll=1`, "get", null, true);
    setMetricLoading(true);
    if (response?.status) {
      setMetricLoading(false);
      setMetricList(response?.data?.records || []);
    } else {
      setMetricLoading(false);
      setMetricList([]);
      message.warning(response?.message);
    }
  };
  const getLocationList = async () => {
    const response = await MakeApiCall(
      `location/menu-with-location`,
      "get",
      null,
      true
    );
    setLocationLoading(true);
    if (response?.status) {
      setLocationLoading(false);

      setLocationList(response?.data || []);
    } else {
      setLocationLoading(false);
      setMetricList([]);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    getLocationList();
    getMetricList();
    // setLoading(true);
    return () => {};
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        addAccessMapping({
          ...values,
          [values?.metric_id ? "location_id" : "metric_id"]: null,
        });
      })
      .catch((info) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    // form.setFieldsValue({
    //   ...selectedRow,
    // });
  }, [catalogList, catalogLocationList]);

  // console.log(selectedType ? , "selectedType");

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            /{" "}
            {Object.keys(selectedRow)?.length === 0
              ? "Content Access Catalog"
              : "Edit Content Access Catalog"}
            <Switch
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e);
                form.setFieldsValue({
                  [e ? "location_id" : "metric_id"]: null,
                  content_catalog_id: "",
                });
              }}
              style={{ position: "relative", top: "1px", left: "10px" }}
              checkedChildren="Metric"
              unCheckedChildren="Location"
            />
          </div>
        </div>
        <div className="px-10">
          <Form form={form} layout="vertical">
            <Form.Item
              shouldUpdate={(prev, current) =>
                prev.userType !== current.userType
              }
            >
              {({ getFieldValue }) => {
                const selectedTypeLocation = locationList
                  ?.map((d) =>
                    d?.location?.map((r) => ({
                      ...d,
                      ...r,
                    }))
                  )
                  ?.flat()
                  ?.filter(
                    (d) => d?.location_id === getFieldValue("location_id")
                  );

                console.log(
                  selectedTypeLocation?.[0]?.menu_item_title,
                  "selectedType"
                );

                return (
                  <>
                    <Form.Item
                      shouldUpdate
                      name={selectedType ? "metric_id" : "location_id"}
                      label={selectedType ? "Metric" : "Location Catalog"}
                      rules={[
                        {
                          required: true,
                          message: `Please select the ${
                            selectedType ? "Metric" : "Location Catalog"
                          }!`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        prefix={
                          `${
                            `${
                              selectedTypeLocation?.[0]?.menu_item_title
                                ? selectedTypeLocation?.[0]?.menu_item_title +
                                  " -"
                                : ""
                            } ` || ""
                          }` || ""
                        }
                        options={
                          selectedType
                            ? metricList?.map((d) => ({
                                label: d?.metric_name,
                                value: d?.id,
                              }))
                            : locationList?.map((d) => ({
                                label: d?.menu_item_title,
                                options: d?.location?.map((r, i) => ({
                                  label: r?.title,
                                  value: r?.location_id,
                                })),
                              }))
                        }
                        onChange={(e) => {
                          form.setFieldsValue({
                            content_catalog_id: "",
                          });

                          getCatalogList(
                            selectedType ? "" : e,
                            false,
                            selectedType ? e : ""
                          );
                        }}
                        loading={selectedType ? metricLoading : locationLoading}
                      />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>

            <Form.Item
              name="content_catalog_id"
              label="Content Catalog"
              rules={[
                {
                  required: true,
                  message: "Please select the Content Catalog!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={catalogList?.map((d) => ({
                  label: (
                    <div>
                      {d?.title}
                      &nbsp;-&nbsp;{d?.type == 1 ? "Info" : "Video"}
                    </div>
                  ),
                  value: d?.id,
                }))}
                loading={catalogLoading}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="ms-3"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCaralog;
