import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-pulse {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #1f97d3;
    animation: load-pulse 0.85s infinite linear;
  }

  @keyframes load-pulse {
    0% {
      transform: scale(0.15);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;
