import React, { useState } from "react";
import { useEffect } from "react";
import { MakeApiCall } from "../../../../apis";
import { Spin } from "antd";

const Edit = ({
  close,
  // selectedRow,
  // setSelectedRow,
  // type,
  addRow,
  id,
  viewPage,
  editRow,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `email-template/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});
      setLoading(false);
    } else {
      setSelectedRow({});
      setLoading(false);
    }
  };

  useEffect(() => {
    if (viewPage !== "add") {
      getRecord();
      setLoading(true);
    } else {
      setLoading(false);
    }
    return () => {};
  }, []);

  const handleChange = (e) => {
    const email = e.target.value;
    const isValid = isValidEmail(email) || email === ""; // Consider empty input as valid

    setIsEmailValid(isValid);

    setSelectedRow({
      ...selectedRow,
      sender_email_address: email,
    });
  };
  return (
    <Spin tip="Loading..." spinning={loading}>
      <div id="kt_content_container" className="container-fluid">
        <div className="row g-2">
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <div className="card-title m-0">
                  <h3
                    className="fw-bold m-0"
                    style={{ textTransform: "capitalize" }}
                  >
                    {viewPage} Email Definitions
                  </h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                <div className="row g-5">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor className="fw-bold fs-6 mb-1">
                        Sender Email Address
                        <small className="ms-1 text-danger">*</small>
                      </label>
                      <input
                        className="form-control form-control-solid fs-7"
                        onChange={handleChange}
                        value={selectedRow?.sender_email_address}
                      />
                      {!isEmailValid && (
                        <div className="text-danger fs-8">
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor className="fw-bold fs-6 mb-1">
                        Slug<small className="ms-1 text-danger">*</small>
                      </label>
                      <input
                        className="form-control form-control-solid fs-7"
                        onChange={(e) => {
                          setSelectedRow({
                            ...selectedRow,
                            slug: e.target.value,
                          });
                        }}
                        value={selectedRow?.slug}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor className="fw-bold fs-6 mb-1">
                        Subject<small className="ms-1 text-danger">*</small>
                      </label>
                      <textarea
                        name
                        className="form-control form-control-solid fs-7"
                        id
                        cols={30}
                        rows={5}
                        onChange={(e) => {
                          setSelectedRow({
                            ...selectedRow,
                            subject: e.target.value,
                          });
                        }}
                        value={selectedRow?.subject}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor className="fw-bold fs-6 mb-1">
                        Email Body<small className="ms-1 text-danger">*</small>
                      </label>
                      <textarea
                        name
                        className="form-control form-control-solid fs-7"
                        id
                        cols={30}
                        rows={10}
                        onChange={(e) => {
                          setSelectedRow({
                            ...selectedRow,
                            content: e.target.value,
                          });
                        }}
                        value={selectedRow?.content}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor className="fw-bold fs-6 mb-1">
                        Replacement Tags
                        <small className="ms-1 text-danger">*</small>
                      </label>
                      <textarea
                        name
                        className="form-control form-control-solid fs-7"
                        id
                        cols={30}
                        rows={5}
                        onChange={(e) => {
                          setSelectedRow({
                            ...selectedRow,
                            tags: e.target.value,
                          });
                        }}
                        value={selectedRow?.tags}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 text-end">
                    <button
                      type="button"
                      onClick={() => close()}
                      className="btn btn-light fs-7 px-10 me-2"
                    >
                      Close
                    </button>

                    {viewPage !== "view" && (
                      <button
                        disabled={
                          Object.values({
                            sender_email_address:
                              selectedRow?.sender_email_address,
                            slug: selectedRow?.slug,
                            subject: selectedRow?.subject,
                            content: selectedRow?.content,
                            tags: selectedRow?.tags,
                          })?.filter((d) => d)?.length !== 5 || !isEmailValid
                        }
                        onClick={() => {
                          if (viewPage === "edit") {
                            // edit
                            editRow(selectedRow);
                          } else {
                            // add
                            addRow(selectedRow);
                          }
                        }}
                        type="submit"
                        className="btn btn-primary fs-7 px-10"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Edit;
