import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Tabs, Table, Input, message, Empty } from "antd";
import { EditIcon, PropsFilter } from "../../../../config";
import { MakeApiCall } from "../../../../apis";

const { TabPane } = Tabs;

const AdDefinitions = () => {
  const [editRow, setEditRow] = useState({});

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedID, setSelectedID] = useState(null);

  const [syncList, setSyncList] = useState([]);
  const [syncLoading, setSyncLoading] = useState(true);

  const adDefinationData = async (data) => {
    const response = await MakeApiCall(
      `ad-definition?is_sync=0`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.records || []);

      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const SyncAdDefinationData = async (data) => {
    const response = await MakeApiCall(
      `ad-definition?is_sync=1`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setSyncList(response?.data?.records || []);
      setSyncLoading(false);
    } else {
      setSyncLoading(false);
      setSyncList([]);
      message.warning(response?.message);
    }
  };

  const UpdateadDefinationData = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/${data?.id}`,
      "put",
      {
        mapped_ad_type: data?.mapped_ad_type,
        is_sync: 0,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      setSelectedID(null);
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.message);
    }
  };

  const SyncUpdateadDefinationData = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/sync/${id}`,
      "put",
      {
        is_sync: 1,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      setSyncLoading(true);
      SyncAdDefinationData();
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    adDefinationData();

    setSyncLoading(true);
    SyncAdDefinationData();

    return () => {};
  }, []);

  const updateMappedAdType = (adProduct, adType, id, newMappedAdType) => {
    setList((currentData) => {
      return {
        ...currentData,
        [adProduct]: {
          ...currentData[adProduct],
          [adType]: currentData[adProduct][adType].map((item) => {
            if (item.id === id) {
              return {
                ...item,
                mapped_ad_type: newMappedAdType,
                isAPI: false,
                edit: true,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const columns = [
    {
      title: "DB Name",
      dataIndex: "ad_type_value",
      key: "ad_type_value",
      filterIndex: "ad_type_value",
      width: 300,
    },
    {
      title: "New Name",
      width: 300,
      filterIndex: "mapped_ad_type",
      render: (d) => {
        if (d?.is_sync === 0) {
          return (
            <Input
              className="w-150px me-3"
              placeholder="Enter New Name"
              value={d?.mapped_ad_type}
              disabled={d?.id === selectedID ? false : true}
              onChange={(e) => {
                updateMappedAdType(
                  d?.ad_product,
                  d?.ad_type,
                  d?.id,
                  e.target.value
                );

                setEditRow({
                  ...editRow,
                  mapped_ad_type: e.target.value,
                });
              }}
            />
          );
        }
        return (
          <div>
            <span className="me-3">{d?.mapped_ad_type}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 350,
      render: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start gap-3">
              {d?.is_sync === 0 && (
                <>
                  <button
                    onClick={() => {
                      setSelectedID(d?.id);
                      if (selectedID) {
                        UpdateadDefinationData(d);
                      }
                    }}
                    style={{
                      background: selectedID === d?.id ? "" : "#2e3f52",
                    }}
                    className="add-btn"
                  >
                    {selectedID === d?.id ? "Save" : "Edit"}
                  </button>
                </>
              )}
              {d?.mapped_ad_type && d?.is_sync === 0 && !d?.edit && (
                <svg
                  onClick={() => {
                    SyncUpdateadDefinationData(d?.id);
                  }}
                  width={18}
                  height={18}
                  fill="#000"
                  className="me-3 cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const columns_edit = [
    {
      title: "DB Name",
      dataIndex: "ad_type_value",
      key: "ad_type_value",
      filterIndex: "ad_type_value",
      width: 300,
    },
    {
      title: "New Name",
      filterIndex: "mapped_ad_type",
      width: 300,
      render: (d) => {
        if (d?.is_sync === 0) {
          return (
            <Input
              className="w-150px me-3"
              placeholder="Enter New Name"
              value={d?.mapped_ad_type}
              disabled={d?.id === selectedID ? false : true}
              onChange={(e) => {
                updateMappedAdType(
                  d?.ad_product,
                  d?.ad_type,
                  d?.id,
                  e.target.value
                );

                setEditRow({
                  ...editRow,
                  mapped_ad_type: e.target.value,
                });
              }}
            />
          );
        }
        return (
          <div>
            <span className="me-3">{d?.mapped_ad_type}</span>
          </div>
        );
      },
    },
  ];

  const MarketingTabs = (props, columns, title, loading) => {
    return (
      <div className="card">
        <div className="card-header mt-2" style={{ maxHeight: "30px" }}>
          <div className="card-title">{title}</div>
        </div>
        <div className="card-body pt-0">
          {Object.entries(props || {})?.length === 0 ? (
            <div
              style={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Empty />
            </div>
          ) : (
            <Tabs defaultActiveKey="0">
              {Object.entries(props || {})?.map(([key, value], i) => (
                <TabPane
                  tab={
                    <span style={{ textTransform: "capitalize" }}>
                      {key?.replace("_", " ")?.toLocaleLowerCase()}
                    </span>
                  }
                  className="pt-5"
                  key={i?.toString()}
                >
                  <Table
                    scroll={{ x: "max-content" }}
                    pagination={{
                      size: "default",
                      showSizeChanger: true,
                    }}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    bordered
                    size="small"
                    columns={columns?.map((d) => ({
                      ...d,
                      className: "minWidth-table",
                      ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    }))}
                    loading={loading}
                    dataSource={value}
                  />
                </TabPane>
              ))}
            </Tabs>
          )}
        </div>
      </div>
    );
  };

  const syncListTabs = [
    "SPONSORED_PRODUCTS",
    "SPONSORED_BRANDS",
    "SPONSORED_DISPLAY",
  ];
  return (
    <Wrapper id="kt_content_container" className="custom-ui container-fluid">
      <div className="row g-2 mt-2">
        {list?.length === 0 ? (
          <></>
        ) : (
          <div className="col-xxl-12">
            <Tabs defaultActiveKey="0" type="card">
              {syncListTabs?.map((d, i) => (
                <TabPane
                  tab={
                    <span style={{ textTransform: "capitalize" }}>
                      {d?.replace("_", " ")?.toLocaleLowerCase()}
                    </span>
                  }
                  key={i?.toString()}
                >
                  {MarketingTabs(
                    list?.[d],
                    columns,
                    "Pending Definitions",
                    loading
                  )}
                </TabPane>
              ))}
            </Tabs>
          </div>
        )}
        {syncList?.length === 0 ? (
          <></>
        ) : (
          <div className="col-xxl-12 mt-5">
            <Tabs defaultActiveKey="0" type="card">
              {syncListTabs?.map((d, i) => (
                <TabPane
                  tab={
                    <span style={{ textTransform: "capitalize" }}>
                      {d?.replace("_", " ")?.toLocaleLowerCase()}
                    </span>
                  }
                  key={i?.toString()}
                >
                  {MarketingTabs(
                    syncList?.[d],
                    columns_edit,
                    "Mapped Definitions",
                    syncLoading
                  )}
                </TabPane>
              ))}
            </Tabs>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default AdDefinitions;
