import { GetLinks } from "../../../config";

export default [
  {
    key: "/version-tracking",
    icon: (
      <i className="ki-duotone ki-parcel-tracking fs-1">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
      </i>
    ),
    label: GetLinks("/version-tracking", "Version Tracking"),
  },
];
