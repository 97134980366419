import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { message, Table, Tag, Tooltip } from "antd";
import { ORIGIN, RenderTable } from "../../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Download } from "lucide-react";
import dayjs from "dayjs";

const DownloadManager = ({ pageTitle }) => {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const DownloadManagerAction = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `download-manager?field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&page=${data?.page || page || 1}&per-page=${data?.pageSize || pageSize}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableData(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setTableLoading(false);
    } else {
      setTableData([]);
      setTotalPage(0);
      setTableLoading(false);
    }
  };

  const convertToCSV = (arr) => {
    // Get the headers from the first object
    const headers = Object.keys(arr[0]);

    // Create header row
    const headerRow = headers.join(",");

    // Create data rows
    const dataRows = arr.map((obj) =>
      headers
        .map((header) => {
          // Escape double quotes and wrap in quotes to handle commas in values
          let value = obj[header];
          value =
            typeof value === "string"
              ? `"${value.replace(/"/g, '""')}"`
              : value;
          return value;
        })
        .join(",")
    );

    // Combine header and data rows
    return [headerRow, ...dataRows].join("\n");
  };

  // Function to trigger CSV download
  const downloadCSV = (data) => {
    // Convert array to CSV string
    const csvString = convertToCSV(data);

    // Create a Blob with the CSV content
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Set link attributes
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");

    // Append to body, click, and remove
    document.body.appendChild(link);
    link.click();
    message.destroy();
    document.body.removeChild(link);

    // Free up memory
    URL.revokeObjectURL(url);
  };

  const UploadManagerDownloadAction = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `upload-manager/download?batch_id=${data?.batch_id || ""}&upload_slug=${
        data?.upload_slug || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      console.log(response, "response");

      // message.success(response?.message);
      downloadCSV(response?.data);
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });

  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };

    setSortFilters(obj);
    DownloadManagerAction({ ...obj });
  };
  useEffect(() => {
    DownloadManagerAction();

    return () => {};
  }, []);

  const columns = [
    {
      title: "Source Type",
      dataIndex: "source_type",
      key: "source_type",
      filterIndex: "source_type",
    },
    {
      title: "Record Type",
      dataIndex: "record_type",
      key: "record_type",
      filterIndex: "record_type",
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      filterIndex: "file_name",
      width: 350,
      render: (text) => (
        <Tooltip title={text} placement="left">
          {" "}
          <div
            style={{
              wordBreak: "break-word",
              width: 300,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "record_status",
      key: "record_status",
      filterIndex: "record_status",
      render: (status) => (
        <Tag color={status === "PENDING" ? "orange" : "green"}>{status}</Tag>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      filterIndex: "start_date",
      render: (timestamp) =>
        dayjs(timestamp, "YYYY-MM-DD").format("MM/DD/YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      filterIndex: "end_date",
      render: (timestamp) =>
        dayjs(timestamp, "YYYY-MM-DD").format("MM/DD/YYYY"),
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      filterIndex: "created_at",
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(), // Convert Unix timestamp to readable date
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      render: (d) => {
        if (d?.record_status !== "DONE") {
          return (
            <div
              className="d-flex"
              style={{ justifyContent: "center", width: "100%" }}
            >
              -
            </div>
          );
        }
        return (
          <div
            className="d-flex"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <a
              onClick={() => {
                if (d?.status === "DONE") {
                  message.destroy();
                  message.warning("Please wait for processing");
                  return;
                }
                window.open(ORIGIN + d?.file_path, "_blank");
              }}
            >
              <Icon
                style={{ color: "#1f97d3" }}
                icon="ic:baseline-download"
                width={22}
              />
            </a>
          </div>
        );
      },
    },
  ];
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row g-2">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <div className="card-title">
                <div className="d-flex align-items-center title-text">
                  <h2 className="mb-0">{pageTitle}</h2>{" "}
                </div>
              </div>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body pt-0 pb-0">
              <Table
                dataSource={tableData}
                loading={tableLoading}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                pagination={false}
                scroll={{ x: "max-content" }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                onChange={handleChange}
                columns={columns?.map((d) => ({
                  ...d,
                  className: "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                bordered
                size="small"
              />
            </div>
            <div className="card-footer pt-0 pb-5">
              <Pagination
                loading={tableLoading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setTableLoading(true);
                  setPageSize(e);
                  setPage(1);
                  DownloadManagerAction({
                    page: 1,
                    pageSize: e,

                    ...sortFilters,
                  });
                }}
                onPageNo={(e) => {
                  setTableLoading(true);
                  setPage(e);
                  DownloadManagerAction({
                    page: e,

                    ...sortFilters,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DownloadManager;
