import { Alert, Button, Divider, Form, Input, Select, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { Wrapper } from "../style";
import Captcha from "./CAPTCHA";
import moment from "moment";
import { LOGINURL } from "../../../config";
const { Option } = Select;

const SellerSignUp = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [emailValidationLoading, setEmailValidationLoading] = useState(false);
  const [couponCodeValidation, setCouponCodeValidation] = useState(false);
  const [reloadCode, setReloadCode] = useState(false);
  const [couponCodeValidationLoading, setCouponCodeValidationLoading] =
    useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [flagListLoading, setFlagListLoading] = useState(true);
  const [flagList, setFlagList] = useState([]);
  const [couponCodeId, setCouponCodeId] = useState(null);

  const [errorView, setErrorView] = useState({
    show: false,
    message: "",
    type: "",
  });

  const next = () => {
    if (current === 0) {
      setCurrent(current + 1);
      setErrorView({
        show: false,
        message: null,
        type: "",
      });
    }
  };
  const prev = () => {
    setCurrent(current - 1);
    if (location?.pathname === "/user-verify") {
      history.replace("/user-verify");
    }
    form.setFieldsValue({
      password: "",
      confirmPassword: "",
      first_name: "",
      last_name: "",
      contact_no: "",
      seller_name: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      captcha: "",
      state: "",
      zip_code: "",
      coupon_code: "",
    });
    setErrorView({
      show: false,
      message: null,
      type: "",
    });
  };
  const getFlagList = async () => {
    setFlagListLoading(true);
    const response = await MakeApiCall("countries", "get", null);

    if (response?.status) {
      setFlagList(response?.data || []);
      setFlagListLoading(false);
    } else {
      message.warning(response?.message);
      setFlagList([]);
      setFlagListLoading(false);
    }
  };

  useEffect(() => {
    getFlagList();
    return () => {};
  }, []);

  const checkEmail = async (values) => {
    setEmailValidationLoading(true);
    const response = await MakeApiCall("auth/seller/step1", "post", {
      ...values,
    });
    if (response?.status) {
      if (response?.data?.is_email) {
        next();
        if (response?.data?.email_message) {
          setErrorView({
            show: true,
            message: response?.data?.email_message,
            type: "success",
          });
        }
      } else {
        if (response?.message) {
          setErrorView({
            show: true,
            message: response?.message,
            type: "error",
          });
        }
      }
      setEmailValidationLoading(false);
    } else {
      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
      setEmailValidationLoading(false);
    }
  };
  const checkCouponCode_ = async (values) => {
    return await MakeApiCall("check-coupon-code", "post", {
      ...values,
    });
  };

  const submit = async (values) => {
    const { contact_code, contact_no, ...rest } = values;

    setSignUpLoading(true);
    const response = await MakeApiCall("auth/seller/signup", "post", {
      ...{
        ...rest,
        contact_no: "+" + contact_code + contact_no,
        status: location.search ? 1 : 0,
        email_verify: location.search ? 1 : 0,
        coupon_code_id: couponCodeId || null,
      },
    });
    if (response?.status) {
      setSignUpLoading(false);
      form.resetFields();

      setErrorView({
        show: true,
        message: response?.message,
        type: "success",
      });
      setTimeout(() => {
        window.location.replace(LOGINURL);
      }, 2000);
    } else {
      setSignUpLoading(false);
      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
    }
  };

  const filterOption = (input, option) => {
    const inputLower = input.toLowerCase();
    const countryName = option.children[1].props.children.toLowerCase();
    const phoneCode = option.children[2].props.children.join("").toLowerCase();
    return countryName.includes(inputLower) || phoneCode.includes(inputLower);
  };

  const CheckToken = async (token) => {
    next();
    form.setFieldsValue({
      email: token?.split("email=")?.[1],
    });
  };

  useEffect(() => {
    if (location?.pathname === "/seller-sign-up" && location.search) {
      CheckToken(location.search);
    }
  }, [location?.pathname]);
  const labelStyle = {
    color: "#A8A8A8",
  };
  const selectBefore = (
    <Form.Item name="contact_code" style={{ height: "13px" }}>
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        loading={flagListLoading}
        showSearch
        className="w-100px mobile-number"
        style={{
          position: "relative",
          height: "38px",
          top: "2px",
        }}
        filterOption={filterOption}
        placeholder={
          <>
            <div
              style={{
                backgroundColor: "#DBDBDB",
                width: "20px",
                height: "15px",
              }}
            ></div>
          </>
        }
      >
        {flagList?.map((d, i) => (
          <Option
            value={d?.phone_code}
            style={{ display: "flex", alignItems: "center" }}
            key={i}
          >
            <img
              style={{
                width: "25px",
                height: "25px",
                objectFit: "contain",
                marginRight: "6px",
              }}
              src={d?.flag_url}
              loading="lazy"
            />
            <span
              className="country_name"
              style={{
                color: "#777",
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: '"Lato", sans-serif',
                marginRight: "6px",
              }}
            >
              {d?.country_name}
            </span>
            <span
              style={{
                color: "#999",
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: '"Lato", sans-serif',
                marginRight: "6px",
              }}
            >
              +{d?.phone_code}
            </span>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
  const steps = [
    {
      title: "",
      content: (
        <>
          <Form.Item
            name="email"
            label={<span style={labelStyle}>Email</span>}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
              {
                required: true,
                message: "Please enter your email address",
              },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className="row">
            <div className="col-12">
              <Divider
                textPaddingInline={0}
                orientationMargin={0}
                orientation="left"
                plain
                style={{ fontSize: "16px", color: "#23262F" }}
              >
                User Info
              </Divider>
            </div>

            <div className="col-6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                ]}
                name="first_name"
                label={<span style={labelStyle}>First Name</span>}
              >
                <Input size="large" placeholder="First Name" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                ]}
                name="last_name"
                label={<span style={labelStyle}>Last Name</span>}
              >
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
            </div>

            <div className="col-6">
              <Form.Item
                label={<span style={labelStyle}>Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                  {
                    pattern: /^\S*$/,
                    message: "Password cannot contain blank spaces!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Password"
                  type="password"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                name="confirmPassword"
                label={<span style={labelStyle}>Confirm Password</span>}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Password mismatch");
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Confirm Password"
                  style={{ width: "100%" }}
                  type="password"
                  size="large"
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            name="contact_no"
            label={<span style={labelStyle}>Mobile Number</span>}
            rules={[
              { required: true, message: "Please enter your mobile number" },
              {
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const regex = /^\d{1,15}$/;
                  if (!regex.test(value)) {
                    return Promise.reject(
                      "Mobile number should only contain digits"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Mobile Number"
              addonBefore={selectBefore}
            />
          </Form.Item>
          <div className="row">
            <Divider
              textPaddingInline={0}
              orientationMargin={0}
              orientation="left"
              plain
              style={{ fontSize: "16px", color: "#23262F" }}
            >
              Seller Info
            </Divider>
          </div>
          <Form.Item
            rules={[
              { required: true, message: "Please enter your seller name" },
            ]}
            name="seller_name"
            label={<span style={labelStyle}>Seller Name</span>}
          >
            <Input size="large" placeholder="Seller Name" />
          </Form.Item>
          <Form.Item
            name="country"
            label={<span style={labelStyle}>Country</span>}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              loading={flagListLoading}
              className=""
              showSearch
              size="large"
              placeholder="Select Country"
            >
              {flagList?.map((d, i) => (
                <Option value={d?.country_name} key={i}>
                  <img
                    style={{
                      width: "25px",
                      height: "25px",
                      objectFit: "contain",
                      marginRight: "6px",
                    }}
                    src={d?.flag_url}
                    loading="lazy"
                  />
                  <span
                    className="country_name"
                    style={{
                      color: "#777",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: '"Lato", sans-serif',
                      marginRight: "6px",
                    }}
                  >
                    {d?.country_name}
                  </span>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="row">
            <div className="col-6">
              <Form.Item
                label={<span style={labelStyle}>Address 1</span>}
                name="address1"
              >
                <Input size="large" placeholder="Address 1" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                label={<span style={labelStyle}>Address 2</span>}
                name="address2"
              >
                <Input size="large" placeholder="Address 2" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <Form.Item
                name="city"
                label={<span style={labelStyle}>City</span>}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item
                name="state"
                label={<span style={labelStyle}>State</span>}
              >
                <Input size="large" placeholder="State" />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item
                name="zip_code"
                label={<span style={labelStyle}>Postal / Zip Code</span>}
              >
                <Input size="large" placeholder="Postal / Zip Code" />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <Divider
              textPaddingInline={0}
              orientationMargin={0}
              orientation="left"
              plain
              style={{ fontSize: "16px", color: "#23262F" }}
            >
              Coupon Info
            </Divider>
          </div>
          <div className="row">
            <div className="col-12"></div>
          </div>
          <Form.Item
            hasFeedback
            id="coupon_code"
            label={<span style={labelStyle}>Coupon Code</span>}
            rules={[
              // {
              //   required: true,
              //   message: "Please Enter Coupon Code!",
              // },
              () => ({
                validator: async (_, value) => {
                  try {
                    const isValid = await checkCouponCode_({
                      coupon_code: value,
                    });

                    setCouponCodeId(isValid?.data?.record?.id || null);
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (isValid?.data?.record?.length !== 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Invalid coupon code, please try again")
                      );
                    }
                  } catch (error) {
                    return Promise.reject(
                      new Error("Validation failed due to API error")
                    );
                  }
                },
              }),
            ]}
            name="coupon_code"
          >
            <Input
              placeholder="Coupon Code"
              allowClear
              enterButton="Apply"
              size="large"
              loading={couponCodeValidationLoading}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <div className="row">
            <div className="col-12">
              <label style={labelStyle}>Captcha</label>
              <Captcha
                setCaptchaStatus={setCaptchaStatus}
                reloadCode={reloadCode}
                captchaStatus={captchaStatus}
                form={form}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <Wrapper className="d-flex flex-column flex-root">
      <div className=" ">
        {/* <a className="d-block d-lg-none mx-auto py-20">
          <img
            alt="Logo"
            src="/assets/media/argometrix-logo.png"
            className="theme-light-show h-25px"
          />
          <img
            alt="Logo"
            src="/assets/media/argometrix-logo.png"
            className="theme-dark-show h-25px"
          />
        </a> */}
        {/*end::Logo*/}
        {/*begin::Aside*/}
        <div className="row">
          <div
            className="col-lg-5 col-md-12 p-10"
            style={{ overflow: "auto", height: "100vh" }}
          >
            <div
              className="d-flex flex-column flex-column-fluid flex-center w-20 p-10"
              // style={{ overflow: "auto", height: "100vh" }}
            >
              {/*begin::Wrapper*/}
              <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
                {/*begin::Header*/}

                <div className="py-20">
                  <div className="form w-100 mw-550px fv-plugins-bootstrap5 fv-plugins-framework">
                    <div className="card-body pt-0 pb-0">
                      <div className="text-start mb-10">
                        <div
                          className="heading-info mb-5 mt-5"
                          style={{ display: "grid", justifyItems: "start" }}
                        >
                          <div className="logo-section mt-2 d-flex justify-content-start">
                            <img
                              style={{ height: "auto", width: "45%" }}
                              src="assets/media/ArgoMetrix_logo_SCREEN.png"
                              alt="homepage"
                              className="dark-logo"
                            />
                          </div>
                          <div
                            id="portal_name_color"
                            className="seller-name-section mt-2"
                          >
                            <p>Seller Intelligence platform</p>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "-10px", textAlign: "center" }}
                          className="heading-text "
                        >
                          <h2 className="heading-text-head mb-2 text-start">
                            Register
                          </h2>
                          <p className="heading-text-line text-start">
                            Enter Your Seller Central Primary User{" "}
                          </p>
                          <div style={{ fontSize: 13 }} className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className="messageBox" />
                            </div>
                          </div>
                        </div>
                        {errorView?.show && (
                          <Alert
                            message={errorView?.message}
                            type={errorView?.type}
                            closable
                            className="mb-5"
                            afterClose={() => {
                              setErrorView({ show: false, message: "" });
                            }}
                          />
                        )}
                        {/*end::Title*/}
                        {/*begin::Text*/}

                        {/* {current === 1 && (
                          <div
                            className="text-primary fw-semibold fs-6"
                            data-kt-translate="general-desc"
                          >
                            <Input
                              disabled
                              size="large"
                              value={form?.getFieldValue("email") || "-"}
                            />
                          </div>
                        )} */}
                        {/*end::Link*/}
                      </div>

                      <Form
                        form={form}
                        name="registrationForm"
                        layout="vertical"
                        initialValues={{
                          contact_code: "1",
                          country: "United States",
                        }}
                      >
                        <div>{steps[current]?.content}</div>

                        <div
                          style={{
                            marginTop: 24,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {current < steps.length - 1 && (
                            <Form.Item style={{ width: "100%" }}>
                              <Button
                                size="large"
                                style={{ width: "100%" }}
                                type="primary"
                                loading={emailValidationLoading}
                                onClick={() => {
                                  form
                                    .validateFields()
                                    .then((values) => {
                                      checkEmail(values);
                                    })
                                    .catch((info) => {});
                                }}
                              >
                                Next
                              </Button>
                            </Form.Item>
                          )}

                          {current > 0 && (
                            <Form.Item>
                              <Button
                                size="large"
                                style={{
                                  borderColor: "#898989",
                                  color: "#202020",
                                }}
                                onClick={() => prev()}
                              >
                                Previous
                              </Button>
                            </Form.Item>
                          )}
                          {current === steps.length - 1 && (
                            <Form.Item>
                              <Button
                                loading={signUpLoading}
                                onClick={() => {
                                  form
                                    .validateFields()
                                    .then((values) => {
                                      const obj = {
                                        ...values,
                                        email: form?.getFieldValue("email"),
                                        type: "seller",
                                      };

                                      submit(obj);
                                    })
                                    .catch((info) => {
                                      setReloadCode(!reloadCode);
                                    });
                                }}
                                size="large"
                                type="primary"
                              >
                                Submit
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </Form>
                      <div className="d-flex justify-content-center py-2">
                        {/*begin::Back link*/}
                        <div className="me-2">
                          <div className="m-0">
                            <span
                              className="text-gray-400 fw-bold fs-5 me-2"
                              data-kt-translate="sign-in-head-desc"
                            >
                              Already have an Account?
                            </span>
                            <Link
                              to={LOGINURL}
                              className="link-primary fw-bold fs-5"
                              data-kt-translate="sign-in-head-link"
                            >
                              Sign In
                            </Link>
                          </div>
                        </div>

                        <div className="m-0"></div>
                      </div>
                      {/*end::Actions*/}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Form*/}
                </div>
                {/*end::Body*/}
                {/*begin::Footer*/}
                <div className="m-0">
                  {/*begin::Toggle*/}

                  {/*end::Menu*/}
                </div>
                {/*end::Footer*/}
              </div>
              {/*end::Wrapper*/}
            </div>
          </div>
          <div className="col-lg-7 col-md-12 d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-center bgi-no-repeat">
            <div className="img-wrapper">
              <img src="assets/media/Seller_Registration_Image.png" />
            </div>
          </div>
          {/* <div
          className="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-center bgi-no-repeat"
          style={{
            backgroundImage:
              "url(https://members.app-sip.com/assets/image/Seller_Registration_Image.png)",
            backgroundSize: "auto 100vh",
            backgroundPosition: "top",
          }}
        /> */}
          {/*begin::Body*/}
        </div>
      </div>
    </Wrapper>
  );
};

export default SellerSignUp;
