import styled from "styled-components";

export const Wrapper = styled.div`
  * {
    transition: 0s !important;
  }
  .ant-spin-dot-item {
    background-color: #ffffff;
  }
  .ant-menu {
    background: transparent !important;
  }
  .ant-menu-submenu-title {
    flex-direction: row-reverse;
  }
  .ant-menu-sub {
    background: #00000033 !important;
  }
  .ant-menu-title-content {
    margin-inline-start: 0px !important;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    flex-direction: row-reverse;
    transition: 0s;
    .ant-menu-item-icon,
    i {
      transition: 0s;
      color: #fff;
    }
    .ant-menu-title-content {
      transition: 0s !important;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 400;
    }
    &:hover {
      .ant-menu-item-icon,
      i {
        transition: 0s;
        color: #fff;
      }
      .ant-menu-title-content {
        transition: 0s !important;
        color: #fff;
      }
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      .ant-menu-item-icon,
      .ant-menu-title-content {
        color: #fff !important;
      }
    }
  }
  .ant-menu .ant-menu-item-selected {
    background-color: #00000000;
    .ant-menu-item-icon,
    i {
      color: #e6e12e !important;
    }
    .ant-menu-title-content {
      color: #e6e12e;
      font-size: 1.1rem;
      font-weight: 400;
    }
  }
  .ant-menu .ant-menu-submenu-arrow {
    inset-inline-end: 47px !important;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    padding-inline-end: 16px !important;
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      i,
      .ant-menu-title-content,
      .ant-menu-item-icon {
        color: #e6e12e !important;
      }
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .btn-primary {
    border-radius: 0.25rem !important;
    color: #3c71b8 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    font-size: small !important;
    .ki-outline {
      color: #3c71b8 !important;
    }
  }
`;
