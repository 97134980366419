import styled from "styled-components";

export const Wrapper = styled.div`
  // .ant-table-wrapper
  //   .ant-table.ant-table-small
  //   .ant-table-tbody
  //   .ant-table-wrapper:only-child
  //   .ant-table {
  //   margin-block: 2px !important;
  //   margin-inline: 0px 0px !important;
  // }
  .ant-table-expanded-row {
    background-color: #ccddef !important;
  }
`;
