import adminApplicationLog from "./admin-application-log";
import adminDashboard from "./admin-dashboard";
import adminSetting from "./admin-setting";
import advertisingDashboard from "./advertising-dashboard";
import applicationLog from "./application-log";
import dashboard from "./dashboard";
import dataSources from "./data-sources";
import emailDefinitions from "./email-definitions";
import financial from "./financial";
import manageUsers from "./manage-users";
import partnerResources from "./partner-resources";
import performanceMetrics from "./performance-metrics";
import requestSupport from "./request-support";
import resources from "./resources";
import sales360 from "./sales-360";
import sellerTracking from "./seller-tracking";
import sellers from "./sellers";
import setting from "./setting";
import traffic360 from "./traffic-360";
import versionTracking from "./version-tracking";

const payload = {
  admin: [
    ...adminDashboard,
    ...manageUsers,
    ...performanceMetrics,
    ...emailDefinitions,
    ...partnerResources,
    ...sellers,
    ...adminSetting,
    ...sellerTracking,
    ...adminApplicationLog,
    ...versionTracking,
  ],
  users: [
    ...dashboard,
    ...dataSources,

    ...financial,
    ...sales360,
    ...traffic360,
    ...advertisingDashboard,

    ...setting,

    ...requestSupport,
    ...resources,
    ...applicationLog,
  ],
};

export default payload;
