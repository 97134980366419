import { Select, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { EditWrapper } from "./style";

const EditPage = ({ id, onClose, UpdateAction }) => {
  const [user_, setUser_] = useState({});

  const getUserActivities = async () => {
    const response = await MakeApiCall(
      `seller-user/view/${id}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setUser_(response?.data?.userData || {});
    } else {
      setUser_({});

      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getUserActivities();
    return () => {};
  }, []);

  return (
    <EditWrapper className="container-fluid">
      <div className="tab-content m-4" id="nav-tabContent">
        {/*  */}
        <h5 className="primaryHeading2 d-flex justify-content-start mt-2 my-3">
          <i
            className="text-primary ki-duotone ki-arrow-left fs-1 me-3 cursor-pointer"
            onClick={() => onClose()}
          >
            <span className="path1" />
            <span className="path2" />
          </i>
          My Sellers
        </h5>
        <div className="row">
          {/* Column */}
          {/* Column */}
          <div className="col-lg-12 col-xlg-12 col-md-12">
            <div className="card">
              <div className="tab-content">
                <div
                  className="tab-pane active show"
                  id="settings"
                  role="tabpanel"
                >
                  <div className="card-body">
                    <div className="form-horizontal">
                      <input
                        type="hidden"
                        name="c_is_active"
                        defaultValue="d79d7937ba6c12999d3f18f18e5e6bd5"
                      />
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Amazon Seller ID"
                        >
                          Amazon Seller ID{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            type="text"
                            className="form-control seller-require"
                            placeholder="Amazon Seller ID"
                            id="form_seller_name"
                            name="amz_seller_id"
                            value={user_?.amazon_seller_id}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                amazon_seller_id: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Seller Name"
                        >
                          Seller Company Name{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="Seller Company Name"
                            id="form_seller_name"
                            name="seller_name"
                            value={user_?.company_name}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                company_name: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        >
                          Address{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="Address1"
                            id="form_address1"
                            name="address1"
                            value={user_?.address1}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                address1: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row ">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        ></label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            id="form_address2"
                            placeholder="Address2"
                            name="address2"
                            value={user_?.address2}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                address2: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row ">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        ></label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            id="form_address3"
                            placeholder="Address3"
                            name="address3"
                            value={user_?.address3}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                address3: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        >
                          City
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="City"
                            id="form_city"
                            name="city"
                            value={user_?.city}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                city: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        >
                          State/Zip{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="State"
                            id="form_state"
                            name="state"
                            value={user_?.state}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                state: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                        <div className="col-sm-4 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="Zip"
                            id="form_zip"
                            name="zip"
                            value={user_?.zip_code}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                zip_code: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Address Info"
                        >
                          Country{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            required
                            type="text"
                            className="form-control seller-require"
                            placeholder="Country"
                            id="form_country"
                            name="country"
                            value={user_?.country}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                country: e.target.value,
                              });
                            }}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="created_at"
                        >
                          Created At{" "}
                        </label>
                        <div className="col-sm-5 ">
                          <input
                            disabled
                            type="text"
                            className="form-control seller-require"
                            placeholder="created_at"
                            id="created_at"
                            name="created_at"
                            value={moment(
                              new Date(user_?.created_at * 1000)
                            ).format("DD/MM/YYYY")}
                          />
                          <small className="form-control-feedback"></small>
                        </div>
                      </div>
                      {/* coupons */}

                      {/* end coupons */}
                      <div className="form-group row ">
                        <label
                          className="col-sm-3 text-right control-label col-form-label"
                          htmlFor="Image"
                        >
                          Status{" "}
                        </label>
                        <div className="col-sm-5">
                          <Select
                            placeholder="Select Status"
                            className="w-150px"
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                status: e,
                              });
                            }}
                            value={user_?.status}
                            options={[
                              {
                                label: "Inactive",
                                value: 0,
                              },
                              {
                                label: "Active",
                                value: 1,
                              },
                            ]}
                          />

                          <small className="form-control-feedback"> </small>
                        </div>
                      </div>
                      <div className="form-group m-b-0">
                        <div className="offset-sm-3 col-sm-5">
                          <input type="hidden" name="redirect" defaultValue />
                          <input
                            type="hidden"
                            name="sellerAlert"
                            defaultValue="seller"
                          />
                          <input
                            type="submit"
                            onClick={() => UpdateAction(user_)}
                            className="btn btn-search btn-sm"
                            defaultValue="Submit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Column */}
        </div>
      </div>
    </EditWrapper>
  );
};

export default EditPage;
