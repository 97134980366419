import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs-tab {
    padding: 0px 0 !important;
  }
  .icon-size {
    svg {
      width: 18px;
      position: relative;
      top: -2px;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #f1f1f1 !important;
    color: #202020 !important;
  }

  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }
  .custom-ui .ant-table-thead .ant-table-cell {
    background-color: lightyellow !important;
  }
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .red {
    transition: 0.5s;
    &:hover {
      .m-card-title {
        text-decoration: underline red 3px !important;
      }
    }
  }
  .yellow {
    transition: 0.5s;
    &:hover {
      .m-card-title {
        text-decoration: underline #925252 3px !important;
      }
    }
  }

  .green {
    transition: 0.5s;
    &:hover {
      .m-card-title {
        text-decoration: underline green 3px !important;
      }
    }
  }

  .card-metric {
    width: 100%;
    height: 197px;
    padding: 12px 0px 0px;
    position: relative;
    border-radius: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3px;
    display: inline-flex;

    .m-card-body {
      margin-bottom: 37px;
      .right-view {
        display: grid;
        justify-items: end;
      }
      .m-card-ema_trend {
        padding: 1px 11px;
        background: white;
        border-radius: 4px;
        div {
          text-align: center;
        }
        // justify-content: center;
        // align-items: center;
        gap: 10px;
        // display: flex;
        margin-bottom: 10px;
      }
      justify-content: space-between;
      align-items: flex-start;
      display: flex;
      margin-top: 7px;
      width: 100%;
      gap: 0.5rem !important;
      padding: 0px 10px;
      .m-card-details-text {
        color: #252f4a !important;
        font-size: 1.075rem !important;
        font-weight: 500 !important;
        overflow-wrap: break-word;
      }
      .m-card-value-h {
        color: rgb(7 20 55) !important;
        font-size: 1.175rem !important;
        font-weight: 500 !important;
        overflow-wrap: break-word;
      }
      .m-card-details {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        gap: 4px;
        margin-top: 7px;
        width: 100%;
      }
    }
    .m-card-header {
      width: auto;
      justify-content: space-between;
      align-items: center;
      display: flex;

      .m-card-title {
        color: rgb(0, 0, 0);
        font-size: 1.275rem;
        font-weight: 500 !important;
        overflow-wrap: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .tabs-antd {
    .ant-tabs-nav {
      margin-bottom: 0;
      position: relative;
      top: 13px;
      .ant-tabs-tab-btn {
        color: var(--bs-text-gray-600) !important;
        font-size: 1.25rem !important;
        font-weight: 500 !important;
      }
      .ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: #252f4a !important;
        font-weight: 500 !important;
        font-size: 1.25rem !important;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          background: #f2f2f2;
          padding: 5px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: #000;
          font-size: 14px;

          font-weight: 400;
        }
      }
    }
  }
  table {
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  .fixed-column {
    // max-width: 300px !important;
  }
  .table-fixed th.fixed-column,
  tr > td.fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f2f2f2;
    padding-right: 10px;
  }
  .container_ {
    max-height: 462px;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 20px;

    .card_ {
      text-align: center;

      border-radius: 8px;
    }
  }
  .yellow {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #eec800 !important;
      border: none !important;
    }
  }
  .blue {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #0051d4 !important;
      border: none !important;
    }
  }
  .purpal {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #ae35b0 !important;
      border: none !important;
    }
  }
  .Expenses {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #009a9d !important;
      border: none !important;
    }
  }
  .Profit {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #009de8 !important;
      border: none !important;
    }
  }
  .net {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #7438ba !important;
      border: none !important;
    }
  }
  .Refunds {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #f97303 !important;
      border: none !important;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }
  .ant-table-tbody {
    tr:first-child {
      display: none;
    }
  }
  .selected-row-financial {
    .ant-table-thead .ant-table-cell {
      background: #f1f1f1 !important;
      color: #202020 !important;
    }
  }

  .ant-btn-primary {
    width: 90px;
    height: 28px;
    font-size: 10px;
    border-radius: 90px;
    background: #1e96d3 !important;
  }
  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }

  .table-container {
    position: relative;
  }
  .left-arrow {
    position: absolute;
    left: 0px;
    font-size: 16px;
    color: #1e1e2d;
    z-index: 9;
    bottom: 0;
    top: 0;
    height: fit-content;
    margin: auto;
  }
  .right-arrow {
    position: absolute;
    right: 0px;
    font-size: 16px;
    color: #1e1e2d;
    z-index: 9;
    bottom: 0;
    top: 0;
    height: fit-content;
    margin: auto;
    transform: rotate(180deg);
  }

  table {
    width: auto;
  }

  th,
  td {
    padding: 5px 15px;

    white-space: nowrap;
  }

  .top-bar-page-header {
    width: 100%;
    box-shadow: rgb(226, 226, 234) 0px -1px 0px inset;

    transition: all 0.6s ease 0s;
    align-items: center;
    display: inline-flex;
    padding-bottom: 10px;
    .page-header-info {
      width: 598px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
      display: inline-flex;
      .discription-text {
        color: rgb(68, 68, 79);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        overflow-wrap: break-word;
      }
      .header-title-page {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;
        display: flex;
        .title-text {
          text-align: center;
          color: rgba(24, 24, 25, 0.9);
          font-size: 18px;
          font-weight: 700;
          overflow-wrap: break-word;
        }
      }
    }
  }
  ._,
  .__,
  .___,
  .____ {
    color: #00000000;
  }
`;
export const TableWrapper = styled.div`
  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .createDateRangePicker {
    .ant-picker-presets {
      ul {
        display: flex;

        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 110px;
      }

      li {
        height: fit-content;
        margin: 5px 0;
      }
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #f1f1f1 !important;
    color: #202020 !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .selected-row-financial {
    .ant-table-thead .ant-table-cell {
      // background: #f1f1f1 !important;
      // color: #202020 !important;
    }
  }
  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }
`;
export const PopOverDropDown = styled.div`
  .reporting-table {
    width: 100%;
    border-collapse: collapse;
    // margin: 20px 0;
    // font-family: Arial, sans-serif;
  }

  .reporting-table th,
  .reporting-table td {
    text-align: left;
    padding: 8px;
    // background-color: #eee; /* Light gray background */
    color: #333; /* Dark text color */
    border: 1px solid #ddd; /* Light gray border */
  }

  .reporting-table th {
    background-color: #002f36;
    color: #fff;
    font-weight: 500;
  }

  .reporting-table td {
    font-size: 0.9em;
  }

  /* Optional: Add styles for alternating row colors */
  .reporting-table tr:nth-child(odd) {
    background-color: #f5f9fa; /* Light gray background */
  }

  /* Style for even rows */
  .reporting-table tr:nth-child(even) {
    background-color: #ffffff; /* Slightly darker background */
  }

  /* Optional: Style for the not connected status */
  .status-not-connected {
    color: #999; /* Lighter text color for "Not Connected" */
  }
`;
