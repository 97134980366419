import styled from "styled-components";

export const Wrapper = styled.div`
  .tabs-antd {
    .ant-tabs-nav {
      margin-bottom: 0;
      position: relative;
      top: 13px;
      .ant-tabs-tab-btn {
        color: var(--bs-text-gray-600) !important;
        font-size: 1.25rem !important;
        font-weight: 500 !important;
      }
      .ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: #252f4a !important;
        font-weight: 500 !important;
        font-size: 1.25rem !important;
      }
    }
  }
`;
