import { Button, Select, Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../common-context";
import { Wrapper } from "./style";

import moment from "moment";
import { MakeApiCall } from "../../../apis";
import InfoVideo from "../../../components/header-icons";
import CredentialsError from "../../credential-page";

const SellerProfileAdmin = ({ id, onClose }) => {
  const contextValue = useContext(GlobalContext);

  const [user_, setUser_] = useState({});

  const [submitLoading, setSubmitLoading] = useState(false);

  const [activityList, setActivityList] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);

  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=9`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const getUserActivities = async () => {
    setActivityLoading(true);
    const response = await MakeApiCall(
      `seller-user/view/${id}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setUser_(response?.data?.userData || {});
      setActivityLoading(false);
      setActivityList(response?.data?.userActivity || []);
    } else {
      setUser_({});
      setActivityList([]);
      setActivityLoading(false);
      message.destroy();
      message.warning(response?.message);
    }
  };
  const updateUser = async (data) => {
    setSubmitLoading(true);
    const response = await MakeApiCall(`seller-user/${id}`, "put", data, true);
    if (response?.status) {
      message.destroy();
      message.success(response?.message);

      setSubmitLoading(false);
      onClose();
    } else {
      setSubmitLoading(false);
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getUserActivities();
    return () => {};
  }, []);

  const columns = [
    {
      title: "Activity",
      dataIndex: ["event_type_data", "event_message"],
      key: "event_message",
    },
    {
      title: "Date and Time (ET)",
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
  ];

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid position-relative"
    >
      <div className="row mt-2">
        <div className="col-xl-4 col-lg-6 col-md-12 ">
          <div className="card mb-5 mb-xl-8">
            <div className="card-header py-0 ">
              <div className="card-title">Seller Profile</div>
            </div>
            <div className="card-body pt-0">
              {/*end::User Info*/}

              <div className="pb-0 fs-6">
                {/*begin::Details item*/}
                <div className="fw-bold ">Amazon Seller ID</div>
                <div className="text-gray-600">
                  {user_?.amazon_seller_id || "-"}
                </div>
                {/*begin::Details item*/}
                <div className="separator separator-dashed my-4" />
                {/*begin::Details item*/}
                <div className="fw-bold ">Address</div>
                <div className="text-gray-600">
                  <a href="#" className="text-gray-600 text-hover-primary">
                    {user_?.address1 || "-"},{user_?.address2 || "-"},
                    {user_?.address3 || "-"},{user_?.city || "-"},
                    {user_?.country || "-"}
                  </a>
                </div>
                {/*begin::Details item*/}
                <div className="separator separator-dashed my-4" />
                {/*begin::Details item*/}
                <div className="fw-bold ">Create At (ET)</div>
                <div className="text-gray-600">
                  {moment(new Date(parseInt(user_?.created_at) * 1000))
                    .tz("America/New_York")
                    .format("MM/DD/YYYY")}
                </div>
              </div>
              <div className="mt-5">
                <Table
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  loading={activityLoading}
                  size="small"
                  pagination={true}
                  scroll={{ x: "max-content", y: "527px" }}
                  columns={columns}
                  dataSource={activityList?.sort((a, b) => {
                    return Number(b.updated_at) - Number(a.updated_at);
                  })}
                />
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>

        <div className="col-xl-8 col-lg-6 col-md-12  ">
          {/*begin::Row*/}
          <div className="row gy-5 g-xl-5">
            {/*begin::Col*/}
            <div className="col-xl-12">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Seller Details</h3>
                  </div>
                </div>

                <div
                  id="kt_account_settings_profile_details"
                  className="collapse show"
                >
                  <div id="kt_account_profile_details_form" className="form">
                    <div className="card-body border-top border-top-dashed p-9">
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amazon Seller ID
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-12 fv-row">
                              <input
                                type="text"
                                name="fname"
                                className="form-control  mb-3 mb-lg-0"
                                placeholder="Amazon Seller ID"
                                value={user_?.amazon_seller_id}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Seller Company Name
                        </label>

                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Seller Company Name"
                            value={user_?.company_name}
                            onChange={(e) => {
                              setUser_({
                                ...user_,
                                company_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Address
                        </label>

                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Address1"
                            value={user_?.address1}
                            onChange={(e) => {
                              setUser_({ ...user_, address1: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4"></label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Address2"
                            value={user_?.address2}
                            onChange={(e) => {
                              setUser_({ ...user_, address2: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4"></label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            value={user_?.address3}
                            onChange={(e) => {
                              setUser_({ ...user_, address3: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          City
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="City"
                            value={user_?.city}
                            onChange={(e) => {
                              setUser_({ ...user_, city: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          State/Zip
                        </label>
                        <div className="col-lg-4 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="State"
                            value={user_?.state}
                            onChange={(e) => {
                              setUser_({ ...user_, state: e.target.value });
                            }}
                          />
                        </div>
                        <div className="col-lg-4 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Zip"
                            value={user_?.zip_code}
                            onChange={(e) => {
                              setUser_({ ...user_, zip_code: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Country
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Country"
                            value={user_?.country}
                            onChange={(e) => {
                              setUser_({ ...user_, country: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Coupon code
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="company"
                            className="form-control "
                            placeholder="Coupon code"
                            value={user_?.coupon_code_id}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Status
                        </label>
                        <div className="col-lg-8 fv-row">
                          <Select
                            value={user_?.status}
                            options={[
                              {
                                label: "Active",
                                value: 1,
                              },
                              {
                                label: "Inactive",
                                value: 0,
                              },
                            ]}
                            onChange={(e) => {
                              setUser_({ ...user_, status: e });
                            }}
                            className="w-200px"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                      <button
                        onClick={onClose}
                        className="btn btn-light btn-active-light-primary me-2 fs-7"
                      >
                        Close
                      </button>
                      <button
                        // type="submit"
                        style={{
                          opacity: submitLoading ? 0.5 : 1,
                          pointerEvents: submitLoading ? "none" : "auto",
                        }}
                        onClick={() => {
                          updateUser(user_);
                        }}
                        type="primary"
                        className="btn btn-primary  fs-7"
                        // id="kt_account_profile_details_submit"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SellerProfileAdmin;
