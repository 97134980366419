import { message, Popconfirm, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import { Wrapper } from "./style";
import Pagination from "../../../../components/pagination";
import AddCatalog from "./lib/add";
import { MakeApiCall } from "../../../../apis";

export default function LocationCatalog() {
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [menuList, setMenuList] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [status, setStatus] = useState(null);
  const [sortFilters, setSortFilters] = useState({
    field_name: "title",
    sort_by: "asc",
  });

  const handleEdit = (row) => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${row?.id}`
    );
  };

  const deleteRow = async (id) => {
    const response = await MakeApiCall(`location/${id}`, "delete", null, true);

    if (response?.status) {
      message.destroy();
      setList((pre) => pre.filter((d) => d?.id !== id));
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const handleDelete = (id) => {
    message.loading("Loading...", 0);

    // Implement your delete logic here
    deleteRow(id);
  };

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{_?.key}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <span>
          <a onClick={() => handleEdit(record)} className="me-2">
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete the location"
            description="Are you sure to delete this location?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className="me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },

    {
      title: "Menu item",
      dataIndex: "menu_item",
      key: "menu_item",
      filterIndex: "menu_item",
      // defaultSort: true,
      // sorter: (a, b) => a?.menu_item?.localeCompare(b?.menu_item),
      // sortDirections: ["ascend"],
      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      filterIndex: "slug",
      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },

    // {
    //   title: "Created (ET)",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   filterIndex: "created_at",
    //   render: (e) => {
    //     return moment(new Date(parseInt(e) * 1000))
    //       .tz("America/New_York")
    //       .format("MM/DD/YYYY hh:mm A");
    //   },
    // },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      title: "Is Pending",
      dataIndex: "is_pending",
      render: (e, row) => {
        return (
          <Tag color={e == 1 ? "orange" : "success"}>
            {e == 1 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (e, row) => {
        return (
          <span className={e == 1 ? "active-seller" : "inactive-seller"}>
            {e == 1 ? "Active" : "Inactive"}
          </span>
        );
      },
    },
  ];

  const getList = async (data) => {
    // `location?page=${data?.page || page || 1}&per-page=${
    //     data?.pageSize || pageSize
    //   }&isAll=0&field_name=${data?.field_name || ""}&sort_by=${
    //     data?.sort_by || ""
    //   }`,
    const response = await MakeApiCall(`location?isAll=1`, "get", null, true);

    if (response?.status) {
      setList(
        response?.data?.records?.map((d, i) => ({ ...d, key: i + 1 })) || []
      );
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      // setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const getMenuList = async (data) => {
    setMenuLoading(true);
    const response = await MakeApiCall(`menu-item?isAll=1`, "get", null, true);

    if (response?.status) {
      setMenuList(
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          title: key,
          label: key,
          options: Object?.entries(value || {})?.map(([key_, value_]) => ({
            label: value_,
            value: key_,
          })),
        })) || []
      );

      setMenuLoading(false);
    } else {
      setTotalPage(0);
      setMenuLoading(false);
      message.warning(response?.message);
    }
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  useEffect(() => {
    setLoading(true);
    getList({ ...sortFilters });

    setMenuLoading(true);
    getMenuList();
    return () => {};
  }, []);

  if (pageType) {
    return (
      <AddCatalog
        setList={setList}
        pageType={pageType}
        id={selectedID}
        getList={() => getList({ ...sortFilters })}
        menuList={menuList}
        menuLoading={menuLoading}
        setVisible={() => {
          getList({ ...sortFilters });
          history.push(updatedUrl);
        }}
      />
    );
  }
  return (
    <Wrapper id="kt_content_container" className="custom-ui">
      <div className="row g-2">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch">
            <div className="card-header">
              <div className="card-title">Location Catalog</div>
              <div className="card-toolbar">
                <Select
                  placeholder="Status"
                  options={[
                    {
                      label: "Pending",
                      value: "1",
                    },
                    {
                      label: "Done",
                      value: "0",
                    },
                  ]}
                  onChange={(e) => {
                    setStatus(e);
                  }}
                  value={status}
                  allowClear
                  className="w-100px me-3"
                />

                <a
                  className="add-btn "
                  onClick={() => {
                    history.push(
                      `${location?.pathname}${location?.search}${
                        location?.search ? "&" : "?"
                      }viewType=add`
                    );
                  }}
                >
                  <svg
                    width={16}
                    height={16}
                    className="me-2"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                  </svg>
                  Add
                </a>
              </div>
            </div>
            <div className="card-body scroll-show pt-0 pb-0">
              <Table
                dataSource={list
                  ?.sort((a, b) => a?.menu_item?.localeCompare(b?.menu_item))
                  ?.filter((d) =>
                    status === "0"
                      ? d?.is_pending === 0
                      : status === "1"
                      ? d?.is_pending === 1
                      : d
                  )}
                // onChange={handleChange}
                columns={columns?.map((d) => ({
                  ...d,
                  className: "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                loading={loading}
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                rowKey={"key"}
                scroll={{ x: "max-content", y: "calc(100vh - 410px)" }}
              />
            </div>
            <div className="card-footer d-none pt-0 pb-5">
              <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    page: 1,
                    pageSize: e,
                    ...sortFilters,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);
                  getList({
                    page: e,
                    ...sortFilters,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
