import styled from "styled-components";

export const Wrapper = styled.div`
  // .ant-tabs-tab {
  //   background-color: #fff !important;
  //   border-radius: 0.475rem !important;
  //   color: var(--bs-nav-link-color);
  //   font-size: 0.95rem !important;
  // }
  // .ant-tabs-tab-active {
  //   background-color: var(--bs-primary) !important;
  //   .ant-tabs-tab-btn {
  //     color: #fff !important;
  //   }
  // }

  .primaryHeading2 {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    flex-direction: row !important;
  }
  .img-circle {
    border-radius: 100% !important;
  }
  .m-t-10 {
    margin-top: 10px !important;
  }
  .card-subtitle {
    font-weight: 300 !important;
    margin-bottom: 15px !important;
    color: #adb5bd !important;
    margin-top: -0.375rem !important;
  }
  hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border: 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.5) !important;
  }

  .text-muted {
    color: #adb5bd !important;
  }
  h6,
  .h6 {
    font-size: 0.975rem !important;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .btn.btn-info {
    border-color: #009efb;
    background-color: #009efb;
  }
  .btn.btn-info:hover:not(.btn-active) {
    border-color: #0082ce;
    background-color: #0082ce;
  }
`;
