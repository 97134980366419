import { Empty, Input, Select, Table, Tag, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
import { RenderTable } from "../../../../config";
const Items = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });
  const [filters, setFilters] = useState({
    search_key: null,
    status: null,
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    {
      title: "ID",
      align: "left",
      width: 150,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Seller SKU",
      dataIndex: "seller_sku",
      key: "seller_sku",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },

    {
      title: "ASIN",
      dataIndex: "asin1",
      key: "asin1",
    },

    {
      title: "Image",

      dataIndex: "image_url",
      key: "image_url",
      render: (e) => {
        return (
          <img
            src={e}
            width={60}
            height={60}
            style={{ borderRadius: "10px" }}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => {
        if (e === "Active") {
          return <Tag color="green">{e || "-"}</Tag>;
        }
        return <Tag color="red">{e || "-"}</Tag>;
      },
    },

    {
      title: "Item Name",
      width: 400,
      dataIndex: "item_name",
      key: "item_name",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },

    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
      width: 500,
      render: (e) => {
        if (!e) return <span>-</span>;
        return (
          <Tooltip
            title={
              <div style={{ overflow: "auto", maxHeight: "250px" }}>{e}</div>
            }
          >
            <div className="ellipsis3">{e || "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",

      key: "price",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Fulfilment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
    },
    {
      title: "Open Date",
      dataIndex: "open_date",
      key: "open_date",
    },
    {
      title: "Item Note",
      dataIndex: "item_note",
      key: "item_note",
    },
    {
      title: "Item Condition",
      dataIndex: "item_condition",
      key: "item_condition",
    },
    {
      title: "Zshop Category",
      dataIndex: "zshop_category1",
      key: "zshop_category1",
    },
    {
      title: "Zshop Browse Path",
      dataIndex: "zshop_browse_path",
      key: "zshop_browse_path",
    },
    {
      title: "Zshop Storefront Feature",
      dataIndex: "zshop_storefront_feature",
      key: "zshop_storefront_feature",
    },

    {
      title: "Will Ship Internationally",
      dataIndex: "will_ship_internationally",
      key: "will_ship_internationally",
    },
    {
      title: "Expedited Shipping",
      dataIndex: "expedited_shipping",
      key: "expedited_shipping",
    },
    {
      title: "Zshop Boldface",
      dataIndex: "zshop_boldface",
      key: "zshop_boldface",
    },
    {
      title: "Add/Delete",
      dataIndex: "add_delete",
      key: "add_delete",
    },
    {
      title: "Pending Quantity",
      dataIndex: "pending_quantity",
      key: "pending_quantity",
      align: "right",
    },

    {
      title: "Open Date",
      dataIndex: "open_date",
      key: "open_date",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Item is Marketplace",
      dataIndex: "item_is_marketplace",
      key: "item_is_marketplace",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Product ID Type",
      dataIndex: "product_id_type",
      key: "product_id_type",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "ZShop Shipping Fee",
      dataIndex: "zshop_shipping_fee",
      key: "zshop_shipping_fee",

      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Item Condition",
      dataIndex: "item_condition",
      key: "item_condition",

      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },

    {
      title: "Merchant Shipping Group",
      dataIndex: "merchant_shipping_group",
      key: "merchant_shipping_group",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Bid for Featured Placement",
      dataIndex: "bid_for_featured_placement",
      key: "bid_for_featured_placement",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",

      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
  ];

  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `report/products?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&is_export=0&search_key=${data?.search_key || ""}&status=${
        data?.status || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      setTableLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters });
    return () => {};
  }, []);

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };
  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Items
          <InfoVideo data={locationData} title={"items"} className={"ms-3"} />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            value={filters?.search_key}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      search_key: e.target.value,
                    },
                    ...sortFilters,
                  });
                }
                return {
                  ...prev,
                  search_key: e.target.value,
                };
              });
            }}
            placeholder="Search by ASIN/SKU"
            className="w-175px ms-3"
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            allowClear={true}
            value={filters?.status}
            onChange={(e) => {
              setFilters({
                ...filters,
                status: e,
              });
              getList({
                ...filters,
                status: e,
              });
            }}
            placeholder="Status"
            options={[
              {
                label: "Active",
                value: "Active",
              },
              {
                label: "Inactive",
                value: "InActive",
              },
              {
                label: "Incomplete",
                value: "Incomplete",
              },
            ]}
            className="w-150px ms-3"
          />
        </div>
        <>
          <div className="card-toolbar">
            <ExportFile
              paramsBody={{
                source_type: "Data Source",
                record_type: "Items",
                param_filter: {
                  search_key: filters?.search_key,
                  status: filters?.status,
                },
              }}
              hideTooltip={true}
              title="Items"
              api={`report/products?is_export=1&field_name=${
                sortFilters?.field_name || ""
              }&sort_by=${sortFilters?.sort_by || ""}&search_key=${
                filters?.search_key || ""
              }&status=${filters?.status || ""}`}
            />
          </div>
        </>
      </div>

      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Items;
